import React, { useEffect, useState } from "react";
import { Button, Grid, IconButton } from "@mui/material";
import { ICard, IGuess, IHand, IPlayRequest, blank } from "../../Types/types";
import Hand from "../Hand";
import Guesses from "../Guesses";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { compareHands } from "../../Types/util";
import _, { forEach, hasIn } from "lodash";

type PlayProps = {
  turnId: string;
  roundAmount: number;
  isPlayersTurn: boolean;
  hand: ICard[];
  pickup: ICard | undefined;
  additionalCard: ICard | undefined;
  canPass: boolean;
  canDraw: boolean;
  emptyState: boolean;
  reset: boolean;
  drawCardFromDeck: () => void;
  pickupCardFromPile: () => void;
  confirmPlay: (play: IPlayRequest) => void;
  setMessage: (msg: string) => void;
};

const Play: React.FC<PlayProps> = ({
  turnId,
  roundAmount,
  isPlayersTurn,
  hand,
  pickup,
  additionalCard,
  canPass,
  canDraw,
  emptyState,
  reset,
  drawCardFromDeck,
  pickupCardFromPile,
  confirmPlay,
  setMessage,
}) => {
  const [activeCards, setActiveCards] = useState<ICard[]>([]);
  const [unusedCards, setUnusedCards] = useState<ICard[]>((hand));
  const [guesses, setGuesses] = useState<IGuess[]>([]);
  const [discardCard, setDiscardCard] = useState<ICard | undefined>(undefined);
  const [word, setWord] = useState("");
  const [wordValue, setWordValue] = useState(0);

  const [confirmed, setConfirmed] = useState<boolean>(false);
  const [isPlayingTurn, setIsPlayingTurn] = useState<boolean>(false);
  const mustPlay = !canPass && isPlayersTurn;


  const blanky = () => {
    setActiveCards([]);
    setUnusedCards([]);
    setGuesses([]);
    setDiscardCard(undefined);
    setWord("");
    setWordValue(0);
    setConfirmed(false);
    setIsPlayingTurn(false);
    setDiscardCardAndHighlight(undefined);
  };

  useEffect(() => {
    blanky();
  }, [reset]);

  const removeFromArray = function (arr: ICard[], theArgs: ICard[]) {
    for (let i = 0; i < theArgs.length; i++) {
      if (arr.map(c => c.letters).includes(theArgs[i].letters)) {
        const ci = arr.map(c => c.letters).indexOf(theArgs[i].letters);
        arr.splice(ci, 1);
      }
    }
    return arr;
  };

  useEffect(() => {
    if (hand) {

      const xxx = _.flatMap(guesses, (g) => g.cards).concat(activeCards);
      const yyy = removeFromArray(hand, xxx);
      setUnusedCards(yyy);
    }
  }, [hand]);


  const setDiscardCardAndHighlight = (card: ICard | undefined) => {
    setDiscardCard(card);
    if (pickup) {
      pickup.isSelected = false;
    }
    forEach(unusedCards, (c) => {
      c.isSelected = false;
    });

    if (card) {
      if (pickup && pickup.letters === card.letters) {
        card.isSelected = true;
        return;
      }
      else {
        const c = _.first(unusedCards.filter((x) => x.letters === card.letters));
        if (c) c.isSelected = true;
        return;
      }
    }
  };

  const clearWords = () => {
    const returnCards = unusedCards.concat(activeCards);
    setActiveCards([]);
    const guessCards = guesses.map((g) => g.cards).flat();
    setGuesses([]);
    setUnusedCards(returnCards.concat(guessCards));
  };

  const callDrawCardFromDeck = () => {
    drawCardFromDeck();
  };

  const callPickupCardFromPile = () => {
    pickupCardFromPile();
  };

  const removeGuess = (guessCards: ICard[]) => {
    setUnusedCards(unusedCards.concat(guessCards));

    if (compareHands(guessCards, activeCards)) setActiveCards([]);
    else {
      for (var i = 0; i < guesses.length; i++) {
        if (compareHands(guessCards, guesses[i].cards)) {
          if (guesses.length === 1) setGuesses([]);
          else if (i === 0) setGuesses(guesses.splice(1, guesses.length - 1));
          else if (i === unusedCards.length - 1)
            setGuesses(guesses.splice(0, guesses.length - 1));
          else {
            const a = guesses.splice(0, i);
            const b = guesses.splice(1, guesses.length - 1);
            setGuesses(a.concat(b));
          }

          return;
        }
      }
    }
  };

  const treatCardAsGuess = (card: ICard) => {
    setWord(word + card.letters);
    setWordValue(wordValue + card.value);
    activeCards.push(card);
    setActiveCards(activeCards);
    const i = unusedCards.indexOf(card);
    if (i === 0) setUnusedCards(unusedCards.splice(1, unusedCards.length - 1));
    else if (i === unusedCards.length - 1)
      setUnusedCards(unusedCards.splice(0, unusedCards.length - 1));
    else {
      const a = unusedCards.splice(0, i);
      const b = unusedCards.splice(1, unusedCards.length - 1);
      const c = a.concat(b);
      setUnusedCards(c);
    }
  };
  const treatCardAsDiscard = (card: ICard) => {
    setDiscardCardAndHighlight(card);
    setMessage("you are discarding " + card.letters + ", confirm?");
  };

  const playCard = (card: ICard) => {
    if (isPlayingTurn) treatCardAsDiscard(card);
    else treatCardAsGuess(card);
  };

  const playWord = () => {
    if (activeCards.length === 0) {
    } else if (activeCards.length < 2)
      setMessage("you need at least 2 cards for a word!");
    else {
      const guess: IGuess = {
        cards: activeCards,
        word: activeCards.map((x) => x.letters).join(""),
        validWord: true,
        canChallenge: false
      };
      guesses.push(guess);
      setGuesses(guesses);
      setActiveCards([]);
      setMessage("");
    }
  };

  const shuffleCards = () => {
    const tmpCards = unusedCards;
    let currentIndex = tmpCards.length,
      randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [tmpCards[currentIndex], tmpCards[randomIndex]] = [
        tmpCards[randomIndex],
        tmpCards[currentIndex],
      ];
    }

    const newCards: ICard[] = [];
    tmpCards.forEach((c) => {
      newCards.push(c);
    });

    setUnusedCards(newCards);
  };

  function discoverPenaltyCards(
    cards: ICard[],
    discard: ICard,
  ): ICard[] {

    const ind = cards.map((x) => x.letters).indexOf(discard.letters);
    if (ind > -1)
      cards.splice(ind, 1);
    // for (let c of ccc) {
    //   const i = cards.map((x) => x.letters).indexOf(c.letters);
    //   console.log("discoverPenaltyCards loop", cards, i);
    //   cards.splice(i, 1);
    // }
    return cards;
  }

  function constructPlay(
    roundAmount: number,
    discard: ICard,
  ): IPlayRequest {
    const unused = unusedCards.length;
    const active = activeCards.length;
    const pile = (pickup ? 1 : 0);
    const cleanGuesses = guesses.filter((g) => g.cards.length > 1);
    const guess = _.flatMap(cleanGuesses, (g) => g.cards).length;

    const unused_active_guess = unused + active + guess;
    const active_guess = active + guess;
    var unplayedCards = unusedCards.filter((c) => !c.isSelected);
    var penaltyCards: ICard[] = [];
    var allGuesses: IGuess[] = [];

    const invalidActiveGuess = activeCards.length === 1;
    const invalidUnusedCards = (pile + unused) > 1;
    const straightOut = (unused_active_guess === roundAmount && pile === 1);
    const drawOut = (pile === 0 && unused === 1 && active_guess === roundAmount);
    const isLegitOut = (!invalidActiveGuess && !invalidUnusedCards && (straightOut || drawOut));
    const isOut = mustPlay || isLegitOut;
    if (isOut) {
      if (pickup) unplayedCards.push(pickup);

      allGuesses =
        activeCards.length < 2
          ? cleanGuesses
          : cleanGuesses.concat({
            cards: activeCards,
            word: activeCards.map((x) => x.letters).join(""),
            validWord: true,
            canChallenge: false
          });
      if (activeCards.length === 1) unplayedCards = unplayedCards.concat(activeCards)
      penaltyCards = isLegitOut ? [] : discoverPenaltyCards(unplayedCards, discard);
    } else {
      unplayedCards = unplayedCards.concat(activeCards).filter((c) => !c.isSelected);
      unplayedCards = unplayedCards.concat(_.flatMap(cleanGuesses, (g) => g.cards));
      const nonDealtIInPlay = pickup && !pickup.isSelected;
      if (nonDealtIInPlay) unplayedCards.push(pickup);
    }

    const p: IPlayRequest = {
      turnId: turnId,
      guesses: allGuesses,
      discard: { ...discard, isSelected: false },
      additionalCard: additionalCard,
      unplayedCards: unplayedCards,
      duds: penaltyCards,
      canPass: canPass,
    };
    return p;
  }

  const tryPlayHand = () => {
    const totalUsableCards = canDraw && pickup ? unusedCards.concat(pickup) : unusedCards;

    if (totalUsableCards.length === 1) {
      const remainder = totalUsableCards[0];
      setDiscardCardAndHighlight(remainder);
      const p = constructPlay(roundAmount, remainder);
      blanky();
      setConfirmed(true);
      confirmPlay(p);
    } else if (canPass && pickup && canDraw && totalUsableCards.length > 1) {
      const highestValueCard = unusedCards.reduce((a, b) => (a.value > b.value) ? a : b);
      setMessage("you are discarding " + highestValueCard.letters + ", confirm?");
      setDiscardCardAndHighlight(highestValueCard);
      setIsPlayingTurn(true);
    } else if (canPass && !canDraw && totalUsableCards.length > 1) {
      setMessage("select a card to discard");
      setIsPlayingTurn(true);
    } else if (unusedCards.length === 0)
      setMessage("you forgot to discard a card");

    else if (mustPlay && !canDraw && unusedCards.length > 0) {
      const highestValueCard = unusedCards.reduce((a, b) => (a.value > b.value) ? a : b);
      setMessage("you are discarding " + highestValueCard.letters + ", confirm?");
      setDiscardCardAndHighlight(highestValueCard);
      setIsPlayingTurn(true);

    } else if (mustPlay && pickup && canDraw && unusedCards.length > 0) {
      const highestValueCard = unusedCards.concat(pickup).reduce((a, b) => (a.value > b.value) ? a : b);
      if (highestValueCard.value < pickup.value)
        setMessage("oooh, too bad. but you can lose fewer points if you discard the " + highestValueCard.letters + ", confirm?");
      else
        setMessage("oooh, too bad. you are discarding " + highestValueCard.letters + ", confirm?");

      setDiscardCardAndHighlight(highestValueCard);
      setIsPlayingTurn(true);

    } else if (mustPlay) {
      setMessage("you need to go out!");
      setIsPlayingTurn(true);
    } else {
      setMessage(" ????????? ");
    }
  };

  return (
    <Grid
      container
      style={{
        border: "0px solid #081400",
        background: "transparent",
      }}
    >
      <Grid
        container
        item
        style={{
          border: "0px solid #081400",
          background: "transparent",
        }}
      >
        <Grid
          container
          item
          xs={12}
          style={{
            paddingTop: "0px",
            paddingBottom: "0px",
            border: "0px solid #081400",
            background: '#ffed4d',
            minHeight: "80px",
          }}>
          <Grid container item xs={11} justifyContent="center"
          >
            <Guesses
              guesses={guesses}
              attempt={activeCards}
              removeGuess={removeGuess}
            />
          </Grid>

          {(guesses.length > 0 || activeCards.length > 0) && (
            <Grid
              container
              item
              xs={1}
              justifyContent="center"
              style={{
                background: "#a5c34f",
                width: '60px',
                paddingTop: "8px",
                marginLeft: "0px",
                borderLeft: "1px solid #081400",
                marginRight: "0px"
              }}
            >
              <IconButton
                style={{
                  background: "#a5c34f",
                  width: "100%",
                  borderLeft: "0px solid #081400",
                }}
                onClick={(e) => clearWords()}
              >
                <KeyboardArrowDownIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>

      </Grid>

      <Grid item xs={12} style={{ background: "transparent" }}>
        <Hand
          cards={unusedCards}
          guessingCards={activeCards}
          isPlayersTurn={isPlayersTurn}
          canDraw={canDraw}
          confirmed={confirmed}
          pickup={pickup}
          emptyState={emptyState}
          reset={reset}
          playCard={playCard}
          callDrawCardFromDeck={callDrawCardFromDeck}
          callPickupCardFromPile={callPickupCardFromPile}
          playWord={playWord}
          shuffleCards={shuffleCards}
        />
      </Grid>


      <Grid
        container
        item
        xs={12}
      >
        <Grid
          container
          item
          direction={"row"}
          style={{
            background: "#ffed4d",
            display: "flex",
            justifyContent: "center",
            margin: "0px"
          }}
        >
          {isPlayersTurn && !isPlayingTurn && (
            <Grid
              container
              item
              style={{
                justifyContent: "center",
                width: "100%",
                margin: "0px",
                //marginTop: "16px"
              }}
            >
              <Button
                disabled={confirmed}
                style={{
                  background: "#235789",
                  color: "#f4f3ee",
                  width: "100%",
                  height: "38.5px",
                  borderTop: "1px solid #081400",
                  padding: "0px",
                  margin: "0px",
                  borderRadius: "0px",
                }}
                onClick={(e) => {
                  tryPlayHand();
                }}
              > {(unusedCards.length === 0 && canDraw) ||
                (unusedCards.length === 1 && !canDraw) || mustPlay
                ? "go out"
                : "pass"}
              </Button>
            </Grid>
          )}

          {isPlayersTurn && isPlayingTurn && !confirmed && (
            <Grid
              container
              item
              style={{
                width: "100%",
                marginLeft: "0px",
              }}
            >
              <Button
                disabled={confirmed}
                style={{
                  background: "#e63946",
                  color: "white",
                  width: "100%",
                  height: "38.5px",
                  borderTop: "1px solid #081400",
                  padding: "0px",
                  margin: "0px",
                  borderRadius: "0px",
                }}
                onClick={(e) => {
                  setIsPlayingTurn(false);
                  setMessage("");
                  setDiscardCardAndHighlight(undefined);
                }}
              > do not go out
              </Button>
            </Grid>
          )}

          {isPlayingTurn && !confirmed && discardCard && (
            <Grid
              container
              item
              style={{
                width: "100%",
                marginLeft: "0px",
              }}
            >
              <Button
                disabled={confirmed}
                style={{
                  background: "#235789",
                  color: "#f4f3ee",
                  width: "100%",
                  height: "38.5px",
                  borderTop: "1px solid #081400",
                  padding: "0px",
                  margin: "0px",
                  borderRadius: "0px",
                }}
                onClick={(e) => {
                  setConfirmed(true);
                  const p = constructPlay(roundAmount, discardCard);
                  blanky();
                  confirmPlay(p);
                }}
              > confirm
              </Button>

            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid >
  );
};

export default Play;
