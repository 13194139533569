import React, { useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { ILobbyGame, ILobbyGamePlayerSummary } from "../../Types/types";
import _ from "lodash";
import Avatar from "../Avatar";
type LobbyGameProps = {
  game: ILobbyGame;
  selectGame: (session: string) => void;
};

const LobbyGame: React.FC<LobbyGameProps> = ({
  game,
  selectGame,
}: LobbyGameProps) => {


  const accent = (x: number, y: number) => {
    if (x < y) return '#ffae03';
    if (x === y) return '#e9190f';
    return 'transparent';
  };
  const borderAccent = (x: number, y: number) => {
    if (x <= y) return '1px solid #081400';
    return '0px solid #081400';
  };

  const summ = game.totals.find((x) => x.player === game.player);
  const isTurn = summ ? summ.isTurn : false;
  const canHaveRoundHighest = game.totals.filter((x) => x.roundTotal !== 0).length > 0;
  const canHaveGameighest = game.totals.filter((x) => x.gameTotal !== 0).length > 0;
  const hand = _.first(game.totals.filter((x) => !x.isOut && x.hand.length > 0));
  return (
    <Button
      style={{
        background: game.isTurn ? "#aad576" : "#e4f7fb",
        width: "350px",
        border: "1px solid #081400",
        borderRadius: "0px",
        padding: "0px",
        margin: "0px",
        minWidth: 0,
        minHeight: 0,
      }}
      onClick={(e) => {
        selectGame(game.sessionId);
      }}
    >
      <Grid
        container
        item xs={12}
        style={{
          textTransform: "none",
        }}
      >
        <Grid container item xs={12} style={{ maxHeight: "10px", minHeight: "10px" }}>
          <Grid container item style={{ width: "12.5%", }}>
            <Grid item style={{ margin: '1px', width: '100px', border: borderAccent(3, game.round), background: accent(3, game.round) }}>  </Grid>
          </Grid>
          <Grid container item style={{ width: "12.5%", }}>
            <Grid item style={{ margin: '1px', width: '100px', border: borderAccent(4, game.round), background: accent(4, game.round) }}> </Grid>
          </Grid>
          <Grid container item style={{ width: "12.5%", }}>
            <Grid item style={{ margin: '1px', width: '100px', border: borderAccent(5, game.round), background: accent(5, game.round) }}> </Grid>
          </Grid>
          <Grid container item style={{ width: "12.5%", }}>
            <Grid item style={{ margin: '1px', width: '100px', border: borderAccent(6, game.round), background: accent(6, game.round) }}> </Grid>
          </Grid>
          <Grid container item style={{ width: "12.5%", }}>
            <Grid item style={{ margin: '1px', width: '100px', border: borderAccent(7, game.round), background: accent(7, game.round) }}> </Grid>
          </Grid>
          <Grid container item style={{ width: "12.5%", }}>
            <Grid item style={{ margin: '1px', width: '100px', border: borderAccent(8, game.round), background: accent(8, game.round) }}> </Grid>
          </Grid>
          <Grid container item style={{ width: "12.5%", }}>
            <Grid item style={{ margin: '1px', width: '100px', border: borderAccent(9, game.round), background: accent(9, game.round) }}> </Grid>
          </Grid>
          <Grid container item style={{ width: "12.5%", }}>
            <Grid item style={{ margin: '1px', width: '100px', border: borderAccent(10, game.round), background: accent(10, game.round) }}> </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs={12}
          style={{
            margin: "0px",
            padding: "0px",
            border: "0px solid #081400",
            textTransform: "none",
          }}
        >

          <Grid container item xs={12} justifyContent="center" >
            {game.totals.map((res: ILobbyGamePlayerSummary, i) => (
              <Grid
                container
                item
                xs={4}
                key={"k-gstss" + i}
                display={"flex"}
                justifyContent={"center"}
                direction={"row"}
                style={{
                  width: "100%",
                  padding: "0px",
                  marginTop: "4px",
                  marginBottom: "0px",
                }}
              >
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent={"center"}
                  style={{
                    width: "100%",
                    height: "36px",
                  }}
                >
                  <Grid
                    container
                    item
                    xs={12}
                    justifyContent={"center"}
                    direction={"row"}
                    style={{
                      marginBottom: "0px",
                    }}
                  >
                    <Grid
                      container
                      item xs={4}
                      justifyContent={"center"}
                      style={{
                      }}
                    >
                      <Avatar
                        avatar={{
                          initial: res.player[0].toUpperCase(),
                          isTurn: game.roundOver || res.isTurn,
                          isPassed: false,
                          isOut: res.isOut
                        }}
                      />
                    </Grid>
                    <Grid
                      container
                      item
                      xs={6}
                      justifyContent={"center"}
                      style={{
                      }}
                    >
                      <Grid
                        container
                        item xs={12}
                        justifyContent={"center"}
                        style={{
                        }}
                      >
                        <Typography
                          variant="h1"
                          style={{
                            color: (res.isTurn) ? "#245501" : "#245501",
                            fontWeight: res.isGameHighest ? 500 : 450,
                          }}
                        >
                          {res.player}
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        item xs={12}
                        justifyContent={"center"}
                        style={{
                        }}
                      >
                        <Typography
                          align="left"
                          variant="h3"
                          style={{
                            lineHeight: "10px",
                            color: canHaveGameighest && res.isGameHighest ? "#7209b7" : "#245501",
                          }}
                        >
                          {res.gameTotal}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {res.isOut && !game.gameOver && (
                  <Grid
                    container
                    item
                    xs={12}
                    justifyContent={"center"}
                    style={{
                      width: "100%",
                    }}
                  >
                    <Grid
                      container
                      item xs={12}
                      justifyContent={"center"}
                      style={{
                        height: "16px",
                      }}
                    >
                      <Typography
                        align="left"
                        variant="h3"
                        style={{
                          color: "#245501",
                          lineHeight: "12px",
                          fontWeight: 500,
                        }}
                      >
                        +
                      </Typography>
                      &nbsp;
                      <Typography
                        align="left"
                        variant="h3"
                        style={{
                          lineHeight: "12px",
                          color: canHaveRoundHighest && res.isRoundHighest ? "#7209b7" : "#245501",
                        }}
                      >
                        {res.roundTotal}
                      </Typography>
                      &nbsp;
                      <Typography
                        align="left"
                        variant="h3"
                        style={{
                          color: "#245501",
                          lineHeight: "12px",
                          fontWeight: 500,
                        }}
                      >points</Typography>
                    </Grid>
                  </Grid>
                )}

              </Grid>
            ))}
          </Grid>
        </Grid>


        {hand && (
          <Grid
            container
            item xs={12}
            justifyContent={"center"}
            style={{
              marginTop: "4px",
              marginBottom: "4px",
            }}
          >
            <Typography
              align="left"
              variant="h1"
              style={{
                color: "#245501",
                fontWeight: 500,
              }}
            >
              {hand ? hand.hand : ""}
            </Typography>
          </Grid>
        )}

      </Grid>
    </Button >
  );
};

export default LobbyGame;
