import React from "react";
import { Grid, Typography } from "@mui/material";

export type PlayeeGameRoundScoreProps = {
  header?: string;
  roundScore: number | undefined;
  runningTotal: number | undefined;
  isGameHighest: boolean;
  isRoundHighest: boolean;
  lb: boolean;
  mb: boolean;
  invalidAWord: boolean;
  failedChallenge: boolean;
};
export const PlayeeGameRoundScore: React.FC<PlayeeGameRoundScoreProps> = ({
  roundScore,
  header,
  runningTotal,
  isGameHighest,
  isRoundHighest,
  lb,
  mb,
  invalidAWord,
  failedChallenge,
}: PlayeeGameRoundScoreProps) => {
  return (
    <Grid
      container
      item
      xs={12}
      justifyContent={"center"}
      style={{ border: "0px solid #0f1919" }}
    >
      {header && (
        <Grid
          container
          item
          xs={12}
          justifyContent={"center"}
        //   style={{ margin: "4px" }}
        >
          <Grid container item xs={12}>
            <Typography
              align="center"
              variant="subtitle1"
              style={{
                color: "#171C21",
                width: "100%",
                marginTop: "4px",
                marginBottom: "4px",
              }}
            >
              {header !== "round" ? header : ""}
            </Typography>
          </Grid>
        </Grid>
      )}
      {!header && (
        <Grid
          container
          item
          xs={12}
          justifyContent={"center"}
        // style={{ margin: "4px" }}
        >
          <Grid container item justifyContent={"center"} xs={6}>
            <Typography
              align="center"
              variant="subtitle1"
              style={{
                color: isRoundHighest ? "green" : "#171C21",
                fontWeight: isRoundHighest ? 500 : 300,
                marginTop: "4px",
                marginBottom: "4px",
                //width: "100%",
              }}
            >
              {roundScore}
            </Typography>

            {lb && (
              <Grid
                item
                style={{
                  background: "transparent",
                  border: "0px solid #0f1919",
                  padding: "0px",
                }}
              >
                <Typography
                  align="center"
                  variant="h6"
                  style={{
                    color: "purple",
                    marginTop: "4px",
                    marginBottom: "4px",
                    marginLeft: "2px",
                  }}
                >
                  lb
                </Typography>
              </Grid>
            )}
            {mb && (
              <Grid
                item
                style={{
                  background: "transparent",
                  border: "0px solid #0f1919",
                }}
              >
                <Typography
                  align="center"
                  variant="h6"
                  style={{
                    color: "darkorange",
                    marginTop: "4px",
                    marginBottom: "4px",
                    marginLeft: "2px",
                  }}
                >
                  mb
                </Typography>
              </Grid>
            )}

            {invalidAWord && (
              <Grid
                item
                style={{
                  background: "transparent",
                  border: "0px solid #0f1919",
                }}
              >
                <Typography
                  align="center"
                  variant="h6"
                  style={{
                    color: "red",
                    marginTop: "4px",
                    marginBottom: "4px",
                    marginLeft: "2px",
                  }}
                >
                  cb
                </Typography>
              </Grid>
            )}

            {failedChallenge && (
              <Grid
                item
                style={{
                  background: "transparent",
                  border: "0px solid #0f1919",
                }}
              >
                <Typography
                  align="center"
                  variant="h6"
                  style={{
                    color: "red",
                    marginTop: "4px",
                    marginBottom: "4px",
                    marginLeft: "2px",
                  }}
                >
                  pb
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid container item xs={6}>
            <Typography
              align="center"
              variant="subtitle1"
              style={{
                color: isGameHighest ? "green" : "#171C21",
                fontWeight: isGameHighest ? 500 : 300,
                width: "100%",
                marginTop: "4px",
                marginBottom: "4px",
              }}
            >
              {runningTotal}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default PlayeeGameRoundScore;
