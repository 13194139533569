import { ICard } from "./types";

export const compareCards = (c1: ICard | undefined, c2: ICard) => {
  if (!c1) return false;
  return c1.letters === c2.letters && c1.value === c2.value;
};

export const compareHands = (h1: ICard[], h2: ICard[]) => {
  if (h1.length !== h2.length) return false;
  for (var i = 0; i < h1.length; i++) {
    if (!compareCards(h1[i], h2[i])) return false;
  }
  return true;
};
