import React, { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";

import {
  ICard,
  IHand,
  IPlayRequest,
} from "../../Types/types";
import Play from "../Play";
import _ from "lodash";
import { HowToVote } from "@mui/icons-material";

type TutorialStep2Props = {
  stepNumber: number;
  isLastStep: boolean;
  progressTutorial: (nextStep: number | undefined) => void;

};

const TutorialStep2: React.FC<TutorialStep2Props> = ({
  stepNumber,
  isLastStep,
  progressTutorial,
}) => {

  const [toggle, setToggle] = useState<boolean>(false);
  const [canProgress, setCanProgress] = useState<boolean>(false);
  const [h1Message, setH1tMessage] = useState<string | undefined>(undefined);
  const [h2Message, setH2Message] = useState<string | undefined>(undefined);
  const [hand, setHand] = useState<ICard[] | undefined>([
    { letters: "B", value: 6, isSelected: false },
    { letters: "O", value: 2, isSelected: false },
    { letters: "N", value: 3, isSelected: false },
  ]);
  const [pickup, setPickup] = useState<ICard | undefined>({ letters: "E", value: 2, isSelected: false },);
  const [additionalCard, setAdditionalCard] = useState<ICard | undefined>(undefined);
  const [word, setWord] = useState<string | undefined>(undefined);
  const [discard, setDiscard] = useState<string | undefined>(undefined);

  const reset = () => {
    setToggle(!toggle);
    setHand([
      { letters: "B", value: 6, isSelected: false },
      { letters: "O", value: 2, isSelected: false },
      { letters: "N", value: 3, isSelected: false },
    ])
    setPickup({ letters: "E", value: 2, isSelected: false });
    setAdditionalCard(undefined);
    setWord(undefined);
    setDiscard(undefined);
    setCanProgress(false);
  };

  const drawCardFromDeck = () => {
    const cards: ICard[] = [{ letters: 'A', value: 2, isSelected: false }, { letters: 'E', value: 2, isSelected: false }, { letters: 'I', value: 2, isSelected: false }, { letters: 'O', value: 2, isSelected: false }, { letters: 'U', value: 2, isSelected: false }];
    const randomIndex = Math.floor(Math.random() * cards.length);
    const ac = cards[randomIndex];
    setAdditionalCard(ac);
    setPickup(undefined);
    setHand([
      { letters: "B", value: 6, isSelected: false },
      { letters: "O", value: 2, isSelected: false },
      { letters: "N", value: 3, isSelected: false },
      ac
    ]);
  }

  const pickupCardFromPile = () => {
    const newHand = (hand && pickup) ? hand.concat([pickup]) : hand;
    setAdditionalCard(pickup);
    setPickup(undefined);
    setHand([
      { letters: "B", value: 6, isSelected: false },
      { letters: "O", value: 2, isSelected: false },
      { letters: "N", value: 3, isSelected: false },
      { letters: "E", value: 2, isSelected: false }
    ]);
  };

  const confirmPlay = (turn: IPlayRequest) => {
    if (turn.guesses.length === 0) {
      const d = turn.discard.letters;
      const msg = 'You have passed your turn and are discarding ' + d
      setH2Message(msg);
      setDiscard(d);
      setH2Message(undefined);
    }
    else if (turn.guesses.length === 1) {
      const w = turn.guesses[0].cards.map(c => c.letters).join("");
      const d = turn.discard.letters;
      const msg = 'You have gone out with ' + w + ' and discarding ' + d
      setH2Message(msg);
      setWord(w);
      setDiscard(d);
      setH2Message(undefined);
      setCanProgress(true);
    }
    setAdditionalCard(undefined);
    setPickup(undefined);
    setHand(undefined);

  };

  return (
    <Grid
      container
      style={{
        border: "0px solid #0f1919",
        background: "#fafafa",
      }}
    >

      <Grid container item xs={12} style={{ padding: "4px", background: 'yellow' }}>
        <Grid container item>
          <Typography
            align="center"
            variant="h1"
            style={{
              width: "100%",
              margin: "4px",
            }}
          >
            lesson 2: pickup an additional card
          </Typography>
        </Grid>
      </Grid>

      <Grid container item xs={12} style={{ padding: "4px", background: 'yellow' }}>
        <Grid container item>
          <Typography
            align="center"
            variant="h3"
            style={{
              width: "100%",
              margin: "4px",
            }}
          >
            GO OUT with a 3-letter word
            {additionalCard?.letters || ''} is available to you to pick up and add to your hand. This card would be discarded by the previous player.
            If {additionalCard?.letters || ''} is not any use to you, you can also pick up a random vard from the deck
          </Typography>
        </Grid>
      </Grid>

      <Grid container item xs={12} style={{ padding: "4px", background: 'yellow' }}>
        <Grid container item>
          <Typography
            align="center"
            variant="h4"
            style={{
              width: "100%",
              margin: "4px",
            }}
          >
            You can spell a word by pressing the letters. You can go out when you have a 3-letter word
          </Typography>
        </Grid>
      </Grid>

      {/* <Grid container item xs={12} style={{ padding: "4px" }}>
        <Grid container item>
          <Typography
            align="center"
            variant="h1"
            style={{
              width: "100%",
              margin: "4px",
            }}
          >
            {titleMessage}
          </Typography>
        </Grid>
      </Grid> */}



      {h1Message && (
        <Grid display="flex" justifyContent="center" container item xs={12} style={{ width: "100%" }}>
          <Typography
            align="center"
            variant="h2"
            style={{
              width: "100%",
              color: "black",
              margin: "4px",
            }}
          >
            {h1Message}
          </Typography>
        </Grid>
      )}

      {h2Message && (
        <Grid display="flex" justifyContent="center" container item xs={12} style={{ width: "100%" }}>
          <Typography
            align="center"
            variant="h2"
            style={{
              width: "100%",
              color: "black",
              margin: "4px",
            }}
          >
            {h2Message}
          </Typography>
        </Grid>
      )}

      {!canProgress && (
        <Grid item xs={12} style={{ margin: "4px", border: "1px solid #0f1919", borderRadius: "4px" }}>
          <Play
            turnId={'tutorial'}
            roundAmount={3}
            hand={hand || []}
            isPlayersTurn={true}
            pickup={pickup}
            additionalCard={additionalCard}
            canPass={true}
            canDraw={!additionalCard}
            drawCardFromDeck={drawCardFromDeck}
            pickupCardFromPile={pickupCardFromPile}
            confirmPlay={confirmPlay}
            emptyState={false}
            reset={toggle}
            setMessage={setH2Message}
          />
        </Grid>
      )}

      {!canProgress && word && discard && (

        <Grid container item xs={12} style={{ padding: "4px", background: 'yellow' }}>
          <Grid container item>
            <Typography
              align="center"
              variant="h3"
              style={{
                width: "100%",
                margin: "4px",
              }}
            >
              {word} may or may not be a real word. Your friends may challenge it later!
            </Typography>
            <Typography
              align="center"
              variant="h3"
              style={{
                width: "100%",
                margin: "4px",
              }}
            >
              You are discarding {discard} and the next player will be able to pick it up and use it in their next hand
            </Typography>
            <Typography
              align="center"
              variant="h3"
              style={{
                width: "100%",
                margin: "4px",
              }}
            >
              Now, all other players will be foreced to GO OUT, if they are ready or not
            </Typography>
          </Grid>
        </Grid>
      )}

      {!canProgress && !word && discard && (

        <Grid container item xs={12} style={{ padding: "4px", background: 'yellow' }}>
          <Grid container item>
            <Typography
              align="center"
              variant="h3"
              style={{
                width: "100%",
                margin: "4px",
              }}
            >
              You have not gone out, you have passed your turn.
            </Typography>
            <Typography
              align="center"
              variant="h3"
              style={{
                width: "100%",
                margin: "4px",
              }}
            >
              You are discarding {discard} and the next player will be able to pick it up and use it in their next hand
            </Typography>
            <Typography
              align="center"
              variant="h3"
              style={{
                width: "100%",
                margin: "4px",
              }}
            >
              Use the button below to try again
            </Typography>
          </Grid>
        </Grid>
      )}

      {canProgress && (

        <Grid container item xs={12} style={{ padding: "4px", background: 'yellow' }}>
          <Grid container item style={{ marginTop: "40px" }}>
            <Typography
              align="center"
              variant="h3"
              style={{
                width: "100%",
                margin: "4px",
              }}
            >
              Well done! You have gone out with {word}
            </Typography>
            <Typography
              align="center"
              variant="h3"
              style={{
                width: "100%",
                margin: "4px",
              }}
            >
              You are discarding {discard} and the next player will be able to pick it up and use it in their next hand
            </Typography>
            <Typography
              align="center"
              variant="h3"
              style={{
                width: "100%",
                margin: "4px",
              }}
            >
              Use the button below to try again
            </Typography>
            <Typography
              align="center"
              variant="h3"
              style={{
                width: "100%",
                margin: "4px",
              }}
            >
              {word} might be challened by your friends localStorage
            </Typography>
            <Typography
              align="center"
              variant="h3"
              style={{
                width: "100%",
                margin: "4px",
              }}
            >
              In lesson 3, you will see how to make multiple words with your cards
            </Typography>
          </Grid>
        </Grid>
      )}

      <Grid item xs={12} display="flex" justifyContent="center" style={{
        background: "yellow",
      }}>
        <Button
          style={{
            background: "green",
            width: "50%",
            height: "38.5px",
            color: "white",
            border: "1px solid #0f1919",
            padding: "8px",
            marginTop: "32px",
            marginBottom: "32px",
            marginRight: "4px",
          }}
          onClick={reset}
        >
          restart lesson {stepNumber}
        </Button>
        <Button
          disabled={!canProgress}
          style={{
            background: canProgress ? "green" : "grey",
            width: "50%",
            color: "white",
            height: "38.5px",
            border: "1px solid #0f1919",
            padding: "8px",
            marginTop: "32px",
            marginBottom: "32px",
            marginLeft: "4px",
          }}
          onClick={(e) => progressTutorial(isLastStep ? undefined : stepNumber + 1)}
        >
          {isLastStep ? 'finish tutorial' : 'next lesson'}
        </Button>
      </Grid>

    </Grid >
  );
};

export default TutorialStep2;
