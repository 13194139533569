import React, { useEffect, useState } from "react";
import { Button, Grid, IconButton, Typography } from "@mui/material";

import {
  ICard,
  IPlayerRoundScore,
  IPlayRequest,
} from "../../Types/types";
import Play from "../Play";
import _ from "lodash";

import ShuffleIcon from "@mui/icons-material/Shuffle";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import RoundSummary from "../RoundSummary";

type TutorialStep3Props = {
  playerName: string;
  stepNumber: number;
  isLastStep: boolean;
  progressTutorial: (nextStep: number | undefined) => void;

};

const TutorialStep3: React.FC<TutorialStep3Props> = ({
  stepNumber,
  playerName,
  isLastStep,
  progressTutorial,
}) => {

  const [toggle, setToggle] = useState<boolean>(false);
  const [canProgress, setCanProgress] = useState<boolean>(false);
  const [titleMessage, setTitleMessage] = useState<string | undefined>('4-zies');
  const [h1Message, setH1tMessage] = useState<string | undefined>(undefined);
  const [h2Message, setH2Message] = useState<string | undefined>(undefined);
  const [hand, setHand] = useState<ICard[]>([
    { letters: "QU", value: 9, isSelected: false },
    { letters: "I", value: 2, isSelected: false },
    { letters: "D", value: 4, isSelected: false },
    { letters: "B", value: 6, isSelected: false },
    { letters: "O", value: 2, isSelected: false },
    { letters: "N", value: 3, isSelected: false },
    { letters: "E", value: 2, isSelected: false },
  ],);
  const [pickup, setPickup] = useState<ICard | undefined>({ letters: "S", value: 3, isSelected: false },);
  const [additionalCard, setAdditionalCard] = useState<ICard | undefined>(undefined);
  const [roundScores, setRoundScores] = useState<IPlayerRoundScore[] | undefined>(undefined);
  const [canDraw, setCanDraw] = useState<boolean>(true);

  const reset = () => {
    setToggle(!toggle);
    setHand([
      { letters: "QU", value: 9, isSelected: false },
      { letters: "I", value: 2, isSelected: false },
      { letters: "D", value: 4, isSelected: false },
      { letters: "B", value: 6, isSelected: false },
      { letters: "O", value: 2, isSelected: false },
      { letters: "N", value: 3, isSelected: false },
      { letters: "E", value: 2, isSelected: false },
    ])
    setPickup({ letters: "S", value: 3, isSelected: false });
    setAdditionalCard(undefined);
    setCanProgress(false);
    setRoundScores(undefined);
    setCanDraw(true);
  };

  const drawCardFromDeck = () => {
    const cards: ICard[] = [{ letters: 'A', value: 2, isSelected: false }, { letters: 'E', value: 2, isSelected: false }, { letters: 'I', value: 2, isSelected: false }, { letters: 'O', value: 2, isSelected: false }, { letters: 'U', value: 2, isSelected: false }];
    const randomIndex = Math.floor(Math.random() * cards.length);
    const ac = cards[randomIndex];
    setAdditionalCard(ac);
    setPickup(undefined);
    setHand([
      { letters: "QU", value: 9, isSelected: false },
      { letters: "I", value: 2, isSelected: false },
      { letters: "D", value: 4, isSelected: false },
      { letters: "B", value: 6, isSelected: false },
      { letters: "O", value: 2, isSelected: false },
      { letters: "N", value: 3, isSelected: false },
      { letters: "E", value: 2, isSelected: false },
      ac
    ]);
  }

  const pickupCardFromPile = () => {
    setAdditionalCard(pickup);
    setPickup(undefined);
    setHand([
      { letters: "QU", value: 9, isSelected: false },
      { letters: "I", value: 2, isSelected: false },
      { letters: "D", value: 4, isSelected: false },
      { letters: "B", value: 6, isSelected: false },
      { letters: "O", value: 2, isSelected: false },
      { letters: "N", value: 3, isSelected: false },
      { letters: "E", value: 2, isSelected: false },
      { letters: "S", value: 3, isSelected: false }
    ]);
  };

  const confirmPlay = (turn: IPlayRequest) => {
    if (turn.guesses.length > 1) {
      const rs = [
        {
          player: playerName,
          score: 20,
          duds: [],
          words: turn.guesses,
          lb: false,
          mb: true,
          mbDeboned: undefined,
          lbDeboned: undefined,
          notAWord: false,
        },
      ];
      setRoundScores(rs);
      setCanProgress(true);
      setCanDraw(false);
    }
  };

  const confirmRound = () => {
  };

  const nextGame = () => {
  };

  const challengeWord = () => {
  };



  return (
    <Grid
      container
      style={{
        border: "0px solid #0f1919",
        background: "#fafafa",
      }}
    >

      <Grid container item xs={12} style={{ padding: "4px", background: 'yellow' }}>
        <Grid container item>
          <Typography
            align="center"
            variant="h1"
            style={{
              width: "100%",
              margin: "4px",
            }}
          >
            lesson 3: make multiple words with your cards
          </Typography>
        </Grid>
      </Grid>

      <Grid container item xs={12} style={{ padding: "4px", background: 'yellow' }}>
        <Grid container item>
          <Typography
            align="center"
            variant="h3"
            style={{
              width: "100%",
              margin: "4px",
            }}
          >
            to complete the lesson, GO OUT with multiple words
          </Typography>
          <Typography
            align="center"
            variant="h3"
            style={{
              width: "100%",
              margin: "4px",
            }}
          >
            the following controls will be useful to know
          </Typography>

          <Grid
            container
            item
            xs={12}
          >
            <Grid container item xs={12}>
              <Grid container item xs={4}>
                <Button
                  style={{
                    background: "lightgreen",
                    color: "black",
                    width: "100px",
                    height: "40px",
                    border: "1px solid #0f1919",
                    padding: "0px",
                    margin: "1px",
                    textTransform: "none",
                  }}
                >
                  i am happy
                </Button>
              </Grid>


              <Grid container item xs={8} style={{
                display: "flex",
                alignItems: "center",
              }}>
                <Typography
                  align="left"
                  variant="h3"
                  style={{
                    color: "#171C21",
                    padding: "4px",
                  }}
                >
                  this button will go green when you start to make a word from the 2 or more cards that you have played
                </Typography>
              </Grid>
            </Grid>
          </Grid>


          <Grid
            container
            item
            xs={12}
          >
            <Grid container item xs={12}>
              <Grid container item xs={2}
                justifyContent={"center"}
                style={{ background: "lightgreen" }}>
                <IconButton
                  style={{
                    background: "lightgreen",
                    color: "black",
                    width: "40px",
                    height: "40px",
                    border: "0px solid #0f1919",
                    padding: "0px",
                    margin: "1px",
                  }}
                >
                  <KeyboardArrowDownIcon />
                </IconButton>
              </Grid>


              <Grid container item xs={10} style={{
                display: "flex",
                alignItems: "center",
              }}>
                <Typography
                  align="left"
                  variant="h3"
                  style={{
                    color: "#171C21",
                    padding: "4px",
                  }}
                >
                  removes guess or removes all guesses
                </Typography>
              </Grid>
            </Grid>
          </Grid>


          <Grid
            container
            item
            xs={12}
            style={{ marginTop: "4px" }}
          >
            <Grid container item xs={12}>
              <Grid container item xs={4}>
                <Button
                  style={{
                    background: "lightgreen",
                    color: "black",
                    width: "100px",
                    height: "40px",
                    border: "1px solid #0f1919",
                    padding: "0px",
                    margin: "1px",
                    textTransform: "none",
                  }}
                >
                  shuffle
                </Button>
              </Grid>

              <Grid container item xs={8} style={{
                display: "flex",
                alignItems: "center",
                padding: "4px",
              }}>
                <Typography
                  align="left"
                  variant="h3"
                  style={{
                    color: "#171C21",
                    padding: "4px",
                  }}
                >
                  rearranges the cards available to you
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Typography
            align="center"
            variant="h3"
            style={{
              width: "100%",
              margin: "4px",
            }}
          >
            if you are happy with your word and want to begin guessing a new word
          </Typography>

        </Grid>
      </Grid>

      <Grid container item xs={12} style={{ padding: "4px", background: 'yellow' }}>
        <Grid container item>
          <Typography
            align="center"
            variant="h4"
            style={{
              width: "100%",
              margin: "4px",
            }}
          >
            You can spell a word by pressing the letters. You can go out when you have a 3-letter word
          </Typography>
          <Typography
            align="center"
            variant="h4"
            style={{
              width: "100%",
              margin: "4px",
            }}
          >
            you can try to spell out the words QUID and BONE
          </Typography>
        </Grid>
      </Grid>

      {
        h1Message && (
          <Grid display="flex" justifyContent="center" container item xs={12} style={{ width: "100%" }}>
            <Typography
              align="center"
              variant="h2"
              style={{
                width: "100%",
                color: "black",
                margin: "4px",
              }}
            >
              {h1Message}
            </Typography>
          </Grid>
        )
      }

      {
        roundScores && (
          <Grid display="flex" justifyContent="center" container item xs={12} style={{ width: "100%" }}>
            <Typography
              align="center"
              variant="h2"
              style={{
                width: "100%",
                color: "black",
                margin: "4px",
              }}
            >
              {playerName}
            </Typography>
          </Grid>
        )
      }

      {
        roundScores && (
          <Grid item xs={12} style={{ padding: "5px" }}>
            <RoundSummary
              roundNumber={3}
              scores={roundScores}
              isRoundOver={true}
              challengeWord={challengeWord}
            />
          </Grid>
        )
      }

      {
        !roundScores && (
          <Grid item xs={12} style={{ margin: "4px", border: "1px solid #0f1919", borderRadius: "4px" }}>
            <Play
              turnId={'tutorial'}
              roundAmount={7}
              hand={hand}
              isPlayersTurn={true}
              pickup={pickup}
              additionalCard={additionalCard}
              canPass={false}
              canDraw={canDraw}
              emptyState={false}
              reset={toggle}
              drawCardFromDeck={drawCardFromDeck}
              pickupCardFromPile={pickupCardFromPile}
              confirmPlay={confirmPlay}
              setMessage={setH2Message}
            />
          </Grid>
        )
      }

      {/* {!canProgress && word && discard && (

        <Grid container item xs={12} style={{ padding: "4px", background: 'yellow' }}>
          <Grid container item>
            <Typography
              align="center"
              variant="h3"
              style={{
                width: "100%",
                margin: "4px",
              }}
            >
              {word} may or may not be a real word. Your friends may challenge it later!
            </Typography>
            <Typography
              align="center"
              variant="h3"
              style={{
                width: "100%",
                margin: "4px",
              }}
            >
              You are discarding {discard} and the next player will be able to pick it up and use it in their next hand
            </Typography>
            <Typography
              align="center"
              variant="h3"
              style={{
                width: "100%",
                margin: "4px",
              }}
            >
              Now, all other players will be foreced to GO OUT, if they are ready or not
            </Typography>
          </Grid>
        </Grid>
      )}

      {!canProgress && !word && discard && (

        <Grid container item xs={12} style={{ padding: "4px", background: 'yellow' }}>
          <Grid container item>
            <Typography
              align="center"
              variant="h3"
              style={{
                width: "100%",
                margin: "4px",
              }}
            >
              You have not gone out, you have passed your turn.
            </Typography>
            <Typography
              align="center"
              variant="h3"
              style={{
                width: "100%",
                margin: "4px",
              }}
            >
              You are discarding {discard} and the next player will be able to pick it up and use it in their next hand
            </Typography>
            <Typography
              align="center"
              variant="h3"
              style={{
                width: "100%",
                margin: "4px",
              }}
            >
              Use the button below to try again
            </Typography>
          </Grid>
        </Grid>
      )} */}

      {
        canProgress && (

          <Grid container item xs={12} style={{ padding: "4px", background: 'yellow' }}>
            <Grid container item style={{ marginTop: "40px" }}>
              <Typography
                align="center"
                variant="h3"
                style={{
                  width: "100%",
                  margin: "4px",
                }}
              >
                Well done! You have gone out with multiple words
              </Typography>
              <Typography
                align="center"
                variant="h3"
                style={{
                  width: "100%",
                  margin: "4px",
                }}
              >
                When you play the most multiple words in a turn you might wing a Multibone
              </Typography>
              <Typography
                align="center"
                variant="h3"
                style={{
                  width: "100%",
                  margin: "4px",
                }}
              >
                A Multibone is a 10 point score bonus for your turn
              </Typography>
              <Typography
                align="center"
                variant="h3"
                style={{
                  width: "100%",
                  margin: "4px",
                }}
              >
                You can lose you Multibone bouns if another player plays as many words as you in their turn
              </Typography>
              <Typography
                align="center"
                variant="h3"
                style={{
                  width: "100%",
                  margin: "4px",
                }}
              >
                In lesson 4, you will learn about the Longbone
              </Typography>
            </Grid>
          </Grid>
        )
      }

      <Grid item xs={12} display="flex" justifyContent="center" style={{
        background: "yellow",
      }}>
        <Button
          style={{
            background: "green",
            color: "white",
            height: "38.5px",
            border: "1px solid #0f1919",
            padding: "8px",
            marginTop: "32px",
            marginBottom: "32px",
            marginRight: "4px",
          }}
          onClick={reset}
        >
          restart lesson {stepNumber}
        </Button>
        <Button
          disabled={!canProgress}
          style={{
            background: canProgress ? "green" : "grey",
            color: "white",
            height: "38.5px",
            border: "1px solid #0f1919",
            padding: "8px",
            marginTop: "32px",
            marginBottom: "32px",
            marginLeft: "4px",
          }}
          onClick={(e) => progressTutorial(isLastStep ? undefined : stepNumber + 1)}
        >
          {isLastStep ? 'finish tutorial' : 'next lesson'}
        </Button>
      </Grid>

    </Grid >
  );
};

export default TutorialStep3;
