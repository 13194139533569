import React, { useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { IDatapoint, IGameSummary, IGameSummaryTotal, ISummaryCell, ISummaryRow } from "../../Types/types";
import _ from "lodash";
import PlayeeGameRoundScore from "../PlayerGameRoundScore";

type GameSummaryProps = {
  summary: IGameSummary;
};

const GameSummary: React.FC<GameSummaryProps> = ({
  summary,
}: GameSummaryProps) => {


  const first = _.first(summary.summaries);
  const last = _.last(summary.summaries);
  const scores: IGameSummaryTotal[] = [];
  var winningPlayers: string[] = [];
  if (first && last) {
    const maxScore = _.maxBy(last.cells, (x) => x.runningTotal)?.runningTotal;
    for (var i = 1; i <= last.cells.length - 1; i++) {
      scores.push({
        player: first.cells[i].header || "",
        total: first.index !== last.index ? last.cells[i].runningTotal || 0 : 0,
        highest:
          last.cells[i].runningTotal === maxScore && (maxScore || 0) > 0,

        lbAmt: 0,
        mbAmt: 0,
      });
      if (last.cells[i].runningTotal === maxScore)
        winningPlayers.push(first.cells[i].header || "");
    }
  }

  return (
    <Grid
      display="flex"
      container
      item
      justifyContent="center"
      style={{
        background: "transparent",
        margin: "0px",
        border: "0px solid #0f1919",
      }}
    >
      <Grid
        container
        item
        xs={12}
        style={{
          background: "transparent",
          margin: "0px",
        }}
      >
        {summary.summaries.length > 1 && summary.summaries.map((cols: ISummaryRow, i) => (
          <Grid
            key={"k-gss" + i}
            container
            item
            xs={12}
            justifyContent={"center"}
            style={{
              background: "transparent",
            }}
          >
            {cols.cells.map((cell: ISummaryCell, i) => (
              <Grid
                key={"k-lpcdfc" + i}
                container
                item
                //xs={2}
                justifyContent={"center"}
                style={{
                  width: i === 0 ? "30px" : "90px",
                  borderTop: "1px solid #0f1919",
                  borderLeft: "1px solid #0f1919",
                  borderBottom: "1px solid #0f1919",
                  borderRight: "1px solid #0f1919",
                  marginTop: -1,
                  marginLeft: -1,
                  paddingTop: 1,
                  paddingLeft: 1,
                }}
              >
                <PlayeeGameRoundScore
                  roundScore={cell.roundScore}
                  header={cell.header}
                  runningTotal={cell.runningTotal}
                  isGameHighest={cell.isGameHighest}
                  isRoundHighest={cell.isRoundHighest}
                  lb={cell.lb}
                  mb={cell.mb}
                  invalidAWord={cell.invalidAWord}
                  failedChallenge={cell.failedChallenge}
                />
              </Grid>
            ))}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default GameSummary;
