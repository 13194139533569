import React from "react";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import { ICard } from "../../Types/types";
import _ from "lodash";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export type GuessProps = {
  cards: ICard[];
  validWord: boolean;
  isCurrentGuess: boolean;
  removeHand: (cards: ICard[]) => void;
};
export const Guess: React.FC<GuessProps> = ({
  cards,
  validWord,
  isCurrentGuess,
  removeHand,
}: GuessProps) => {
  const guessValue = _.sumBy(cards, (x) => x.value);
  return (
    <Grid
      container
      display="flex"
      direction={"row"}
      style={{
        background: !validWord ? "pink" : isCurrentGuess ? "#d0cdc7" : "#eeba0b",
        padding: "0px",
        minHeight: "44px",
        border: "1px solid #081400",
        borderRadius: "0Px",
      }}
    >
      <Grid
        display="flex"
        justifyContent="center"
        style={{
          paddingTop: "9px",
          paddingLeft: "10px",
          minWidth: "50px",
          marginLeft: "4px",
          marginRight: "4px",
        }}
      >
        {cards.map((card: ICard, i) => (
          <Grid
            key={"k-gu" + i}
            style={{
              background: "transparent",
              marginRight: "4px",
              paddingTop: "0px",
            }}
          >
            <Typography
              align="center"
              style={{
                fontSize: "25px",
                color: "#171C21",
                lineHeight: "25px",
                fontWeight: "250",
                width: "100%",
              }}
            >
              {card.letters}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Grid display="flex" style={{ minWidth: "50px" }}>
        <Grid
          style={{ paddingTop: "8px", marginLeft: "12px", marginRight: "12px" }}
        >
          <Typography
            align="center"
            style={{
              fontSize: "20px",
              color: "#171C21",
              lineHeight: "20px",
              fontWeight: "500",
              width: "100%",
            }}
          >
            {guessValue}
          </Typography>
          <Typography
            align="center"
            style={{
              fontSize: "10px",
              color: "#171C21",
              lineHeight: "10x",
              fontWeight: "240",
              width: "100%",
              marginTop: "-5px",
            }}
          >
            points
          </Typography>
        </Grid>

        <Grid
          justifyContent="center"
          style={{
            background: "#a5c34f",
            paddingTop: "8px",
            borderLeft: "1px solid #081400",
            marginLeft: "0px",
            marginRight: "0px"
          }}
        >
          <IconButton
            style={{
              background: "#a5c34f",
              width: "28px",
              height: "28px",
              borderLeft: "0px solid #081400",
            }}
            onClick={(e) => removeHand(cards)}
          >
            <KeyboardArrowDownIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Guess;
