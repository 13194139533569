import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { ICard, IGuess } from "../../Types/types";
import _ from "lodash";
import Guess from "../Guess";

export type GuessesProps = {
  guesses: IGuess[];
  attempt: ICard[];
  removeGuess: (cards: ICard[]) => void;
};
export const Guesses: React.FC<GuessesProps> = ({
  guesses,
  attempt,
  removeGuess,
}: GuessesProps) => {
  return (
    <Grid
      display="flex"
      justifyContent="center"
      style={{
        paddingTop: "16px",
        paddingBottom: "16px",
        minHeight: "55px",
        background: "transparent",
        margin: "0px",
        border: "0px solid #0f1919",
      }}
    >
      <Grid container item justifyContent="center">
        {guesses.map((guess: IGuess, i) => (
          <Grid
            key={"k-gugu" + i}
            style={{
              background: "transparent",
              border: "0px solid #0f1919",
              margin: "2px",
            }}
          >
            <Guess
              cards={guess.cards}
              validWord={guess.validWord}
              isCurrentGuess={false}
              removeHand={removeGuess}
            />
          </Grid>
        ))}
        {attempt.length > 0 && (
          <Grid
            style={{
              background: "transparent",
              border: "0px solid #0f1919",
              padding: "0px",
              margin: "2px",
            }}
          >
            <Guess
              cards={attempt}
              validWord={true}
              isCurrentGuess={true}
              removeHand={removeGuess}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default Guesses;
