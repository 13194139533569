import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { ICard, IAvatar } from "../../Types/types";
import _ from "lodash";
import Avatar from "../Avatar";

export type AvatarsProps = {
  avatars: IAvatar[];
};
export const Avatars: React.FC<AvatarsProps> = ({
  avatars,
}: AvatarsProps) => {
  return (
    <Grid
      container item
      xs={12}
      display="flex"
      justifyContent="center"
      style={{
        paddingTop: "0px",
        paddingBottom: "0px",
        width: "100%",
        background: "transparent",
        margin: "0px",
        marginBottom: '0px',
        border: "0px solid #0f1919",
      }}
    >
      <Grid container item justifyContent="center">
        {avatars.map((avatar: IAvatar, i) => (
          <Grid
            key={"k-gugu" + i}
            style={{
              background: "transparent",
              border: "0px solid #0f1919",
              marginLeft: "4px",
              marginRight: "4px",
            }}
          >
            <Avatar
              avatar={avatar}
            />
          </Grid>
        ))}

      </Grid>
    </Grid>
  );
};

export default Avatars;
