import React, { useEffect, useState } from "react";
import { Button, Grid, IconButton } from "@mui/material";
import { ICard, IGuess, IHand, IPlayRequest, ISpace, blank } from "../../Types/types";
import Hand from "../Hand";
import Guesses from "../Guesses";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  compareHands,
  findCommonSpaces,
  makeSpaces, removeCardsFromListOfCards,
} from "../../Types/util";
import _, { forEach } from "lodash";

type PlayProps = {
  turnId: string;
  roundAmount: number;
  isPlayersTurn: boolean;
  cards: ICard[];
  pickup: ICard | undefined;
  additionalCard: ICard | undefined;
  canPass: boolean;
  canDraw: boolean;
  emptyState: boolean;
  reset: boolean;
  drawCardFromDeck: () => void;
  pickupCardFromPile: () => void;
  confirmPlay: (play: IPlayRequest) => void;
  setMessage: (msg: string) => void;
};

const Play: React.FC<PlayProps> = ({
  turnId,
  roundAmount,
  isPlayersTurn,
  cards,
  pickup,
  additionalCard,
  canPass,
  canDraw,
  emptyState,
  reset,
  drawCardFromDeck,
  pickupCardFromPile,
  confirmPlay,
  setMessage,
}) => {
  const [spaces, setSpaces] = useState<ISpace[]>((makeSpaces(cards, 1)));
  const [guessingSpaces, setGuessingSpaces] = useState<ISpace[]>(([]));
  const [guessingCards, setGuessingCards] = useState<ICard[]>(([]));
  const [unusedCards, setUnusedCards] = useState<ICard[]>((cards));
  const [guesses, setGuesses] = useState<IGuess[]>([]);
  const [discardCard, setDiscardCard] = useState<ICard | undefined>(undefined);
  const [word, setWord] = useState("");
  const [wordValue, setWordValue] = useState(0);

  const [confirmed, setConfirmed] = useState<boolean>(false);
  const [isPlayingTurn, setIsPlayingTurn] = useState<boolean>(false);
  const mustPlay = !canPass && isPlayersTurn;

  const blanky = () => {
    setSpaces(makeSpaces([], 1));
    setGuessingSpaces([]);
    setGuessingCards([]);
    setUnusedCards([]);
    setGuesses([]);
    setDiscardCard(undefined);
    setWord("");
    setWordValue(0);
    setConfirmed(false);
    setIsPlayingTurn(false);
    setDiscardCardAndHighlight(undefined);
  };

  // useEffect(() => {
  //   if (spaces && spaces.length > 0) {
  //     console.log("XXX useEffect spaces", spaces);
  //     // const gss = spaces.filter((c) => c.card && c.card.isCurrectGuess);
  //     // console.log("XXX useEffect spaces gss", gss);
  //     // setGuessingSpaces(gss);
  //     // setGuessingCards(_.compact(gss.map((c) => c.card)));
  //     // const uss = spaces.filter((c) => c.card && !c.card.isCurrectGuess && !c.card.isPlayed);
  //     // setUnusedCards(_.compact(uss.map((c) => c.card)));
  //   }
  // }, [spaces]);

  useEffect(() => {
    console.log('resetting')
    blanky();
  }, [reset]);

  const doTheCardsChange = (cards1: ICard[], cards2: ICard[]): boolean => {
    if (cards1.length !== cards2.length) return true;
    if (cards1.length === 0) return false;
    const xxx = cards1.map(x => x.letters).join('');
    const yyy = cards2.map(x => x.letters).join('');
    return xxx !== yyy;
  }

  useEffect(() => {
    const existingCards = _.compact(spaces.map((s) => s.card));
    const shouldSwap = doTheCardsChange(existingCards, cards);
    const addBlanks = existingCards.length === 0;
    if (addBlanks) {
      console.log('firsties')
      const ss = makeSpaces(cards, 1)
      setSpaces(ss);
      setUnusedCards(cards);
    }
    else if (shouldSwap) {
      console.log('swapping')
      console.log('cards', cards.map(x => x.letters))
      console.log('existingCards', existingCards.map(x => x.letters))
      const toAddCards = removeCardsFromListOfCards(cards, existingCards);
      console.log('toAddCards', toAddCards.map(x => x.letters))
      console.log('spaces', spaces.map(x => x.card?.letters))
      const commonSpaces = findCommonSpaces(spaces, cards);
      console.log('commonSpaces', commonSpaces.map(x => x.card?.letters))
      const rebasedCommonSpaces = commonSpaces.map((s, i) => {
        return { ...s, id: i + 1 };
      });
      console.log('rebasedCommonSpaces', rebasedCommonSpaces.map(x => x.card?.letters))
      const toAddSpaces = toAddCards.map((c, i) => {
        return { id: (rebasedCommonSpaces.length + 1 + i), card: c, isInPlay: true }
      });
      console.log('toAddSpaces', toAddSpaces.map(x => x.card.letters))
      const combinedSpaces = rebasedCommonSpaces.concat(toAddSpaces);
      console.log('combinedSpaces', combinedSpaces.map(x => x.card?.letters))
      setSpaces(combinedSpaces);
      const combinedUnusedCards = unusedCards.concat(toAddCards);
      console.log('combinedUnusedCards', combinedUnusedCards.map(x => x.letters))
      setUnusedCards(combinedUnusedCards);
    } else
      console.log('not swapping')
  }, [cards]);

  const setDiscardCardAndHighlight = (card: ICard | undefined) => {
    console.log("setDiscardCardAndHighlight", card);
    setDiscardCard(card);
    if (pickup) {
      pickup.isSelectedForDiscard = false;
    }
    forEach(unusedCards, (c) => {
      c.isSelectedForDiscard = false;
    });

    if (card) {
      if (pickup && pickup.letters === card.letters) {
        card.isSelectedForDiscard = true;
        return;
      }
      else {
        const c = unusedCards.find((x) => x.id === card.id);
        if (c) c.isSelectedForDiscard = true;
        return;
      }
    }
  };

  const clearWords = () => {
    // const returnCards = unusedCards.concat(activeCards);

    const adjustedSpaces = spaces.map((s) => {
      return { ...s, isInPlay: true }
    });
    setSpaces(adjustedSpaces);
    setGuesses([]);
    setGuessingSpaces([]);

    setGuessingCards([]);
    const uss = adjustedSpaces.filter((s) => s.isInPlay);
    setUnusedCards(_.compact(uss.map((c) => c.card)));

    // const guessCards = guesses.map((g) => g.cards).flat();
    // setUnusedCards(returnCards.concat(guessCards));
  };

  const callDrawCardFromDeck = () => {
    drawCardFromDeck();
  };

  const callPickupCardFromPile = () => {
    pickupCardFromPile();
  };

  const removeGuess = (cards: ICard[]) => {
    const isCurrentGuess = compareHands(cards, guessingCards)
    console.log("removeGuess", isCurrentGuess, cards);
    const removeIds = cards.map((c) => c.id);
    const adjustedSpaces = spaces.map((s) => {
      const iip = (removeIds.includes(s.card?.id || ''))
      const sc = cards.filter((c) => c.id === s.card?.id)[0];
      const modifiedCard: ICard = { ...sc, isCurrectGuess: false };
      return {
        ...s,
        card: iip ? modifiedCard : s.card,
        isInPlay: iip ? true : s.isInPlay
      }
    });
    setSpaces(adjustedSpaces);

    setGuessingSpaces([]);
    setGuessingCards([]);
    const uss = adjustedSpaces.filter((s) => s.isInPlay);
    setUnusedCards(_.compact(uss.map((c) => c.card)));

    if (!isCurrentGuess) {
      for (var i = 0; i < guesses.length; i++) {
        if (compareHands(cards, guesses[i].cards)) {
          if (guesses.length === 1) setGuesses([]);
          else if (i === 0) setGuesses(guesses.splice(1, guesses.length - 1));
          else if (i === unusedCards.length - 1)
            setGuesses(guesses.splice(0, guesses.length - 1));
          else {
            const a = guesses.splice(0, i);
            const b = guesses.splice(1, guesses.length - 1);
            setGuesses(a.concat(b));
          }
          return;
        }
      }
    }
  };

  const treatCardAsGuess = (spaceId: number, card: ICard) => {
    setWord(word + card.letters);
    setWordValue(wordValue + card.value);
    const adjustedSpaces = spaces.map((s) => {
      const modifiedCard = s.card ?
        {
          ...s.card,
          isCurrectGuess: (s.card && s.card.id === card?.id) ? true : false
        }
        : undefined;
      return {
        ...s,
        card: modifiedCard,
        isInPlay: (s.id === spaceId) ? false : s.isInPlay
      }
    })
    setSpaces(adjustedSpaces);

    const gss = guessingSpaces.concat({ id: spaceId, card: card, isInPlay: false });
    setGuessingSpaces(gss);
    setGuessingCards(_.compact(gss.map((c) => c.card)));
    const uss = adjustedSpaces.filter((s) => s.isInPlay);
    setUnusedCards(_.compact(uss.map((c) => c.card)));

    // activeCards.push(card);
    // setActiveCards(activeCards);
    //const i = unusedCards.indexOf(card);
    // if (i === 0) setUnusedCards(unusedCards.splice(1, unusedCards.length - 1));
    // else if (i === unusedCards.length - 1)
    //   setUnusedCards(unusedCards.splice(0, unusedCards.length - 1));
    // else {
    //   const a = unusedCards.splice(0, i);
    //   const b = unusedCards.splice(1, unusedCards.length - 1);
    //   const c = a.concat(b);
    //   setUnusedCards(c);
    // }
  };

  const treatCardAsDiscard = (card: ICard) => {
    setDiscardCardAndHighlight(card);

    setMessage("you are discarding " + card.letters + ", confirm?");
  };

  const playCard = (spaceId: number, card: ICard) => {
    if (isPlayingTurn) {
      treatCardAsDiscard(card);
      // const adjustedSpaces = spaces.map((s) => {
      //   return {
      //     ...s,
      //     isInPlay: (s.id === spaceId) ? false : s.isInPlay
      //   }
      // })
      // setSpaces(adjustedSpaces);
    }
    else treatCardAsGuess(spaceId, card);

    // const adjustedSpaces = spaces.map((s) => {
    //   return { ...s, isInPlay: (s.id === spaceId) ? false : s.isInPlay }
    // });
    // setSpaces(adjustedSpaces);
    // const uss = adjustedSpaces.filter((s) => s.isInPlay);
    // setUnusedCards(_.compact(uss.map((c) => c.card)));
  };

  const playWord = () => {
    // const newWordSpaces = spaces.filter((space) => space.card && space.card.isCurrectGuess);
    // const newWord = _.compact(guessingCards.map((c) => c));
    if (guessingCards.length === 0) {
    } else if (guessingCards.length < 2)
      setMessage("you need at least 2 cards for a word!");
    else {
      const guess: IGuess = {
        cards: guessingCards,
        word: guessingCards.map((x) => x.letters).join(""),
        validWord: true,
        canChallenge: false
      };
      guesses.push(guess);
      setGuesses(guesses);

      const adjustedSpaces = spaces.map((s) => {
        const modifiedCard = s.card ? { ...s.card, isCurrectGuess: false, isPlayed: s.card.isCurrectGuess } : undefined;
        return {
          ...s,
          card: modifiedCard,
        }
      });
      setSpaces(adjustedSpaces);
      setGuessingSpaces([]);
      setGuessingCards([]);
      const uss = adjustedSpaces.filter((s) => s.isInPlay);
      setUnusedCards(_.compact(uss.map((c) => c.card)));

      setMessage("");
    }
  };

  const shuffleCards = () => {
    const tmpSpaces = spaces;
    let currentIndex = tmpSpaces.length,
      randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [tmpSpaces[currentIndex], tmpSpaces[randomIndex]] = [
        tmpSpaces[randomIndex],
        tmpSpaces[currentIndex],
      ];
    }

    const newSpaces: ISpace[] = [];
    tmpSpaces.forEach((c) => {
      newSpaces.push(c);
    });

    setSpaces(newSpaces);
  };

  function moveCard(from: number, to: number): void {
    // console.log("moveCard 1", from, to);
    const tmp = _.cloneDeep(spaces);
    const fromSpace = _.clone(tmp[from - 1]);
    const toSpace = _.clone(tmp[to - 1]);
    [tmp[from - 1], tmp[to - 1]] = [{ ...toSpace, id: from }, { ...fromSpace, id: to }];
    //tmp.forEach((p) => console.log("moveCard", p.id, p.card?.letters))
    setSpaces(tmp);
  }

  function discoverPenaltyCards(
    cards: ICard[],
    discard: ICard,
  ): ICard[] {

    const ind = cards.map((x) => x.letters).indexOf(discard.letters);
    if (ind > -1)
      cards.splice(ind, 1);
    // for (let c of ccc) {
    //   const i = cards.map((x) => x.letters).indexOf(c.letters);
    //   console.log("discoverPenaltyCards loop", cards, i);
    //   cards.splice(i, 1);
    // }
    return cards;
  }

  function constructPlay(
    roundAmount: number,
    discard: ICard,
  ): IPlayRequest {
    const unused = unusedCards.length;
    const guessing = guessingSpaces.length;
    const pile = (pickup ? 1 : 0);
    const cleanGuesses = guesses.filter((g) => g.cards.length > 1);
    const guess = _.flatMap(cleanGuesses, (g) => g.cards).length;

    const unused_guessing_guess = unused + guessing + guess;
    const guessing_guess = guessing + guess;
    var unplayedCards = unusedCards.filter((c) => !c.isSelectedForDiscard);
    var penaltyCards: ICard[] = [];
    var allGuesses: IGuess[] = [];

    const invalidActiveGuess = guessingSpaces.length === 1;
    const invalidUnusedCards = (pile + unused) > 1;
    const straightOut = (unused_guessing_guess === roundAmount && pile === 1);
    const drawOut = (pile === 0 && unused === 1 && guessing_guess === roundAmount);
    const isLegitOut = (!invalidActiveGuess && !invalidUnusedCards && (straightOut || drawOut));
    const isOut = mustPlay || isLegitOut;
    if (isOut) {
      if (pickup) unplayedCards.push(pickup);

      const guessCards = _.compact(guessingSpaces.map((c) => c.card));
      allGuesses =
        guessCards.length < 2
          ? cleanGuesses
          : cleanGuesses.concat({
            cards: guessCards,
            word: guessCards.map((x) => x.letters).join(""),
            validWord: true,
            canChallenge: false
          });
      if (guessCards.length === 1) {

        const adjustedSpaces = spaces.map((s) => {
          const modifiedCard = s.card ? { ...s.card, isCurrectGuess: false } : undefined;
          return {
            ...s,
            card: modifiedCard,
          }
        });
        setSpaces(adjustedSpaces);

        //unplayedCards = unplayedCards.concat(activeCards)
      }
      penaltyCards = isLegitOut ? [] : discoverPenaltyCards(unplayedCards, discard);
    } else {
      unplayedCards = unplayedCards.concat(guessingCards).filter((c) => !c.isSelectedForDiscard);
      unplayedCards = unplayedCards.concat(_.flatMap(cleanGuesses, (g) => g.cards));
      const nonDealtIInPlay = pickup && !pickup.isSelectedForDiscard;
      if (nonDealtIInPlay) unplayedCards.push(pickup);
    }

    const p: IPlayRequest = {
      turnId: turnId,
      guesses: allGuesses,
      discard: { ...discard, isSelectedForDiscard: false },
      additionalCard: additionalCard,
      unplayedCards: unplayedCards,
      duds: penaltyCards,
      canPass: canPass,
    };
    return p;
  }

  const tryPlayHand = () => {
    const totalUsableCards = canDraw && pickup ? unusedCards.concat(pickup) : unusedCards;

    if (totalUsableCards.length === 1) {
      const remainder = totalUsableCards[0];
      setDiscardCardAndHighlight(remainder);
      const p = constructPlay(roundAmount, remainder);
      // blanky();
      setConfirmed(true);
      confirmPlay(p);
    } else if (canPass && pickup && canDraw && totalUsableCards.length > 1) {
      const highestValueCard = unusedCards.reduce((a, b) => (a.value > b.value) ? a : b);
      setMessage("you are discarding " + highestValueCard.letters + ", confirm?");
      setDiscardCardAndHighlight(highestValueCard);
      setIsPlayingTurn(true);
    } else if (canPass && !canDraw && totalUsableCards.length > 1) {
      setMessage("select a card to discard");
      setIsPlayingTurn(true);
    } else if (unusedCards.length === 0)
      setMessage("you forgot to discard a card");

    else if (mustPlay && !canDraw && unusedCards.length > 0) {
      const highestValueCard = unusedCards.reduce((a, b) => (a.value > b.value) ? a : b);
      setMessage("you are discarding " + highestValueCard.letters + ", confirm?");
      setDiscardCardAndHighlight(highestValueCard);
      setIsPlayingTurn(true);

    } else if (mustPlay && pickup && canDraw && unusedCards.length > 0) {
      const highestValueCard = unusedCards.concat(pickup).reduce((a, b) => (a.value > b.value) ? a : b);
      if (highestValueCard.value < pickup.value)
        setMessage("oooh, too bad. but you can lose fewer points if you discard the " + highestValueCard.letters + ", confirm?");
      else
        setMessage("oooh, too bad. you are discarding " + highestValueCard.letters + ", confirm?");

      setDiscardCardAndHighlight(highestValueCard);
      setIsPlayingTurn(true);

    } else if (mustPlay) {
      setMessage("you need to go out!");
      setIsPlayingTurn(true);
    } else {
      setMessage(" ????????? ");
    }
  };

  return (
    <Grid
      container
      style={{
        border: "0px solid #081400",
        background: "transparent",
      }}
    >
      <Grid
        container
        item
        style={{
          border: "0px solid #081400",
          background: "transparent",
        }}
      >
        <Grid
          container
          item
          xs={12}
          style={{
            paddingTop: "0px",
            paddingBottom: "0px",
            border: "0px solid #081400",
            background: '#FBFFB9',
            minHeight: "80px",
          }}>
          <Grid container item xs={11} justifyContent="center"
          >
            <Guesses
              guesses={guesses}
              attempt={guessingCards}
              removeGuess={removeGuess}
            />
          </Grid>

          {(guesses.length > 0 || guessingSpaces.length > 0) && (
            <Grid
              container
              item
              xs={1}
              justifyContent="center"
              style={{
                background: "#a5c34f",
                width: '60px',
                paddingTop: "8px",
                marginLeft: "0px",
                borderLeft: "1px solid #081400",
                marginRight: "0px"
              }}
            >
              <IconButton
                style={{
                  background: "#a5c34f",
                  width: "100%",
                  borderLeft: "0px solid #081400",
                }}
                onClick={(e) => clearWords()}
              >
                <KeyboardArrowDownIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12} style={{ background: "transparent" }}>
        <Hand
          spaces={spaces}
          guessingCards={guessingCards}
          isPlayersTurn={isPlayersTurn}
          canDraw={canDraw}
          confirmed={confirmed}
          pickup={pickup}
          emptyState={emptyState}
          reset={reset}
          playCard={playCard}
          callDrawCardFromDeck={callDrawCardFromDeck}
          callPickupCardFromPile={callPickupCardFromPile}
          playWord={playWord}
          shuffleCards={shuffleCards}
          moveCard={moveCard}
        />
      </Grid>

      <Grid
        container
        item
        xs={12}
      >
        <Grid
          container
          item
          direction={"row"}
          style={{
            background: "#ffed4d",
            display: "flex",
            justifyContent: "center",
            margin: "0px"
          }}
        >
          {isPlayersTurn && !isPlayingTurn && (
            <Grid
              container
              item
              style={{
                justifyContent: "center",
                width: "100%",
                margin: "0px",
              }}
            >
              <Button
                disabled={confirmed}
                style={{
                  background: "#235789",
                  color: "#f4f3ee",
                  width: "100%",
                  height: "38.5px",
                  borderTop: "1px solid #081400",
                  padding: "0px",
                  margin: "0px",
                  borderRadius: "0px",
                }}
                onClick={(e) => {
                  tryPlayHand();
                }}
              > {(unusedCards.length === 0 && canDraw) ||
                (unusedCards.length === 1 && !canDraw) || mustPlay
                ? "go out"
                : "pass"}
              </Button>
            </Grid>
          )}

          {isPlayersTurn && isPlayingTurn && !confirmed && (
            <Grid
              container
              item
              style={{
                width: "100%",
                marginLeft: "0px",
              }}
            >
              <Button
                disabled={confirmed}
                style={{
                  background: "#e63946",
                  color: "white",
                  width: "100%",
                  height: "38.5px",
                  borderTop: "1px solid #081400",
                  padding: "0px",
                  margin: "0px",
                  borderRadius: "0px",
                }}
                onClick={(e) => {
                  setIsPlayingTurn(false);
                  setMessage("");
                  setDiscardCardAndHighlight(undefined);
                }}
              > do not go out
              </Button>
            </Grid>
          )}

          {isPlayingTurn && !confirmed && discardCard && (
            <Grid
              container
              item
              style={{
                width: "100%",
                marginLeft: "0px",
              }}
            >
              <Button
                disabled={confirmed}
                style={{
                  background: "#235789",
                  color: "#f4f3ee",
                  width: "100%",
                  height: "38.5px",
                  borderTop: "1px solid #081400",
                  padding: "0px",
                  margin: "0px",
                  borderRadius: "0px",
                }}
                onClick={(e) => {
                  setConfirmed(true);
                  const p = constructPlay(roundAmount, discardCard);
                  blanky();
                  confirmPlay(p);
                }}
              > confirm
              </Button>

            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid >
  );
};

export default Play;
