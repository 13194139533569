import React, { useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { ICard } from "../../Types/types";

export type CardProps = {
  card: ICard;
  isSmall: boolean;
  play: (turn: ICard) => void;
};
export const Card: React.FC<CardProps> = ({
  card,
  isSmall,
  play,
}: CardProps) => {
  const [isPlayed, setIsPlayed] = useState<boolean>(false);

  const calcFontSize = (small: boolean, letters: string) => {
    if (small) {
      if (letters?.length > 1) return "20px";
      return "20px";
    } else {
      if (letters?.length > 1) return "24px";
      return "24px";
    }
  }
  // linear-gradient(  45deg,     rgba(0,0,0,0.5) 25%,     transparent 25%,     rent 50%,     rgba(0,0,0,0.5) 50%,     rgba(0,0,0,0.5) 75%,     transparent 75%,     transparent   )
  // background: card.isSelected ?
  // "linear-gradient(          45deg,           #a9cff1 25%,           white 25%,           white 50%,           #a9cff1 50%,           #a9cff1 75%,           white 75%,           white          )":
  // "linear-gradient(          45deg,           #a9cff1 25%,           red 25%,           red 50%,           #a9cff1 50%,           #a9cff1 75%,           red 75%,           red          )",

  return (
    <Button
      style={{
        background: "linear-gradient(45deg, #a9cff1 25%, white 25%, white 50%, #a9cff1 50%, #a9cff1 75%, white 75%, white) 5px 5px",
        backgroundSize: "5px 5px",
        // background: "linear-gradient(to left top, transparent 10px, #A9CFF1 10px)",
        width: isSmall ? "40px" : "50px",
        height: isSmall ? "58px" : "70px",
        border: "1px solid #0f1919",
        padding: "0px",
        margin: "0px",
        minWidth: 0,
        minHeight: 0,
        boxShadow: card.isSelected ? '0 0 5px red' : '0 0 0px #171C21',
        textShadow: "0 2px 3px white",
        //box-shadow: 0 0 15px hsla(0deg, 0%, 0%, 0.5);
        //text-shadow: 0 2px 3px hsla(0deg, 0%, 0%, 0.25);
      }}
      //disabled={!canInteractWith || isPlayed}
      onClick={(e) => {
        setIsPlayed(true);
        play(card);
      }}
    >
      <Grid container direction={"row"} style={{}}>
        <Typography
          style={{
            fontSize: calcFontSize(isSmall, card.letters),
            color: "#171C21",
            lineHeight: isSmall ? "25px" : "35px",
            fontWeight: "500",
            width: "100%",
          }}
        >
          {card.letters}
        </Typography>
        <Typography
          style={{
            fontSize: isSmall ? "15px" : "20px",
            color: "#171C21",
            lineHeight: isSmall ? "20px" : "25px",
            fontWeight: "340",
            width: "100%",
          }}
        >
          {card.value}
        </Typography>
      </Grid>
    </Button>
  );
};

export default Card;
