import React, { useState } from "react";
import { Chip, Grid, IconButton, TextField, Typography } from "@mui/material";
import _ from "lodash";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

export type ChatChipsProps = {
  chatChips: string[];
  addChatChip: (chatChip: string) => void;
  removeChatChip: (chatChip: string) => void;
};
export const ChatChips: React.FC<ChatChipsProps> = ({
  chatChips,
  addChatChip,
  removeChatChip,
}: ChatChipsProps) => {
  const [chip, setChip] = useState("");

  const doAdd = () => {
    if (chip && chip.length > 0) {
      addChatChip(chip);
      setChip('');
    }
  };

  return (
    <Grid
      container item
      display="flex"
      justifyContent="center"
      style={{
        paddingTop: "0px",
        paddingBottom: "0px",
        minHeight: "55px",
        background: "trsnparent",
        margin: "0px",
        border: "0px solid #0f1919",
      }}
    >
      <Grid container item xs={12} >
        <Typography
          variant="h1"
          style={{
            color: "#0f1919",
            margin: "4px",
          }}
        >
          chat chips
        </Typography>
      </Grid>


      {chatChips.length === 0 && (
        <Grid container item xs={12} justifyContent="center" >
          <Typography
            align="center"
            variant="h1"
            style={{
              color: "#0f1919",
              margin: "4px",
            }}
          >
            no chat chips
          </Typography>
        </Grid>

      )}

      <Grid container item xs={12} style={{
        paddingTop: "0px",
        paddingBottom: "0px",
        background:
          "linear-gradient(0deg,  lightgray 25%,  transparent 25%,  transparent 50%,   lightgray 50%,   lightgray 75%,  transparent 75%,  transparent )",
        backgroundSize: "5px 5px",

        width: "100%",
        borderRadius: "4px",
        padding: "4px",
        border: "0px solid #0f1919",
      }}>
        {chatChips
          .map((chatChip: string, i) => (
            <Grid
              container
              item
              key={"k-gudfgku" + i}
              style={{
                width: "100%",
              }}
            >
              <Chip
                label={chatChip} variant="outlined" style={{
                  background: "white",
                  border: "1px solid #0f1919",
                  margin: "2px",
                }} />
              <IconButton
                style={{
                  background: "red",
                  color: "white",
                  width: "30px",
                  height: "30px",
                  border: "1px solid #0f1919",
                  margin: "2px",
                }}
                onClick={(e) => { removeChatChip(chatChip) }}
              >
                <RemoveIcon />
              </IconButton>
            </Grid>
          ))}
      </Grid>

      <Grid
        container
        item
        xs={10}
        style={{
          margin: "4px",
          marginTop: "20px",
        }}
      >
        <Grid
          container
          item
          justifyContent={"center"}
          xs={10}
          style={{
            background: "transparent",
            border: "0px solid #0f1919",
            width: "100%",
            marginTop: "4px",
            marginBottom: "4px",
          }}
        >
          <TextField
            id="outlined-basic"
            label="Add new chat chip"
            value={chip}
            style={{
              background: "white",
              width: "100%",
              padding: "0px",
            }}
            onChange={(event) => setChip(event.target.value)}
            variant="outlined"
          />
        </Grid>

        <Grid
          container
          item
          justifyContent="center"
          xs={2}
        >
          <IconButton
            style={{
              background: "green",
              color: "white",
              width: "40px",
              height: "40px",
              border: "1px solid #0f1919",
              padding: "0px",
              marginTop: "8px",
            }}
            onClick={(e) => { doAdd() }}
          >
            <AddIcon />
          </IconButton>
        </Grid>
      </Grid>


    </Grid >
  );
};

export default ChatChips;
