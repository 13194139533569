import React, { useEffect, useState } from "react";
import { Button, Grid, TextField, IconButton, Typography, List, ListItem } from "@mui/material";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonRemoveAlt1Icon from '@mui/icons-material/PersonRemoveAlt1';
import ShuffleIcon from "@mui/icons-material/Shuffle";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import HomeIcon from '@mui/icons-material/Home';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import PersonIcon from '@mui/icons-material/Person';
import _ from "lodash";

const HowToPlay: React.FC = () => {
  return (
    <Grid
      container
      display="flex"
      justifyContent="center"
      style={{
        background: "transparent",
        paddingTop: "0px",
        border: "0px solid #0f1919",
      }}
    >
      <Grid
        container
        item
        xs={12}
      >

        <Grid container item xs={12}>

          <Grid container item xs={12} style={{
            display: "flex",
            alignItems: "center",
            padding: "4px",
          }}>
            <Typography
              align="left"
              variant="h1"
              style={{
                color: "#171C21",
                padding: "4px",
              }}
            >
              How to play
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
      >

        <Grid container item xs={12}>
          <List sx={{ listStyleType: 'disc' }}>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography
                variant="h3"
                style={{
                  color: "#171C21",
                }}
              >
                a game consists of 8 rounds. the player with the most points after 8 rounds wins the game. you score points by making words from available letters. letters have different point values
              </Typography>

            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography
                variant="h3"
                style={{
                  color: "#171C21",
                }}
              >
                the first round starts with 3 dealt cards. each subsequent round is dealth an additional 1 card. the last round starts with 14 cards. in each round, you can also use a card of known value that represents the discarded card from the previous player, and an unseen card of unknown value that represents the deck of cards
              </Typography>

            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography
                variant="h3"
                style={{
                  color: "#171C21",
                }}
              >
                a word must be at least 2 cards long. you can make a word by using 2 or more cards from your hand. you can also use cards from your hand and the discard pile. you can also use cards from your hand and the discard pile and the cards that are already on the table
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography
                variant="h3"
                style={{
                  color: "#171C21",
                }}
              >
                there are bonuses in each round for the player who plays the lonest word, or plays the most words
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <Typography
                variant="h3"
                style={{
                  color: "#171C21",
                }}
              >
                you can play any word you want. your friends may challenge a word that may not be agree as a group is a word. after a word is challenged, everybody will be asked if they think the word is valid. if any player decided the word is invalid, then you will score 0 points for the word. if all players decide it is a word then the challenging player will lose points to the value of the challenged word.
              </Typography>
            </ListItem>
          </List>
        </Grid>
      </Grid>



      <Grid
        container
        item
        xs={12}
      >

        <Grid container item xs={12}>

          <Grid container item xs={12} style={{
            display: "flex",
            alignItems: "center",
            padding: "4px",
          }}>
            <Typography
              align="left"
              variant="h1"
              style={{
                color: "#171C21",
              }}
            >
              Useful terminology
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
      >
        <Grid container item xs={12}>

          <Grid container item xs={2} style={{
            display: "flex",
            alignItems: "center",
            padding: "4px",
          }}>
            <Typography
              align="left"
              variant="h3"
              style={{
                color: "#171C21",
                padding: "4px",
              }}
            >
              long bone
            </Typography>
          </Grid>
          <Grid container item xs={10} style={{
            display: "flex",
            alignItems: "center",
          }}>
            <Typography
              align="left"
              variant="h3"
              style={{
                color: "#171C21",
                padding: "4px",
              }}
            >
              a special 10 point bonus for playing the longest word in a round
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
      >
        <Grid container item xs={12}>

          <Grid container item xs={2} style={{
            display: "flex",
            alignItems: "center",
            padding: "4px",
          }}>
            <Typography
              align="left"
              variant="h3"
              style={{
                color: "#171C21",
                padding: "4px",
              }}
            >
              multi bone
            </Typography>
          </Grid>
          <Grid container item xs={10} style={{
            display: "flex",
            alignItems: "center",
          }}>
            <Typography
              align="left"
              variant="h3"
              style={{
                color: "#171C21",
                padding: "4px",
              }}
            >
              a special 10 point bonus for playing the most amount of words in a round
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
      >
        <Grid container item xs={12}>

          <Grid container item xs={2} style={{
            display: "flex",
            alignItems: "center",
            padding: "4px",
          }}>
            <Typography
              align="left"
              variant="h3"
              style={{
                color: "#171C21",
              }}
            >
              debone
            </Typography>
          </Grid>
          <Grid container item xs={10} style={{
            display: "flex",
            alignItems: "center",
            padding: "4px",
          }}>
            <Typography
              align="left"
              variant="h3"
              style={{
                color: "#171C21",
                padding: "4px",
              }}
            >
              the act of removing a possiblility of another player from gaining a bone bonus
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
      >
        <Grid container item xs={12}>

          <Grid container item xs={2} style={{
            display: "flex",
            alignItems: "center",
            padding: "4px",
          }}>
            <Typography
              align="left"
              variant="h3"
              style={{
                color: "#171C21",
                padding: "4px",
              }}
            >
              go out
            </Typography>
          </Grid>
          <Grid container item xs={10} style={{
            display: "flex",
            alignItems: "center",
          }}>
            <Typography
              align="left"
              variant="h3"
              style={{
                color: "#171C21",
                padding: "4px",
              }}
            >
              the act playing a full set of words and forcing the other players to play their cards
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
      >
        <Grid container item xs={12}>

          <Grid container item xs={2} style={{
            display: "flex",
            alignItems: "center",
            padding: "4px",
          }}>
            <Typography
              align="left"
              variant="h3"
              style={{
                color: "#171C21",
                padding: "4px",
              }}
            >
              pass
            </Typography>
          </Grid>
          <Grid container item xs={10} style={{
            display: "flex",
            alignItems: "center",
          }}>
            <Typography
              align="left"
              variant="h3"
              style={{
                color: "#171C21",
                padding: "4px",
              }}
            >
              the act of not going out. the game will continue and you will get another chance to play in the round
            </Typography>
          </Grid>
        </Grid>
      </Grid>


      <Grid
        container
        item
        xs={12}
      >

        <Grid container item xs={12}>

          <Grid container item xs={12} style={{
            display: "flex",
            alignItems: "center",
            padding: "4px",
          }}>
            <Typography
              align="left"
              variant="h1"
              style={{
                color: "#171C21",
                padding: "4px",
              }}
            >
              what do the buttons do?
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
      >
        <Grid container item xs={12}>
          <Grid container item justifyContent="center" xs={2}>
            <Button
              style={{
                background: "green",
                color: "black",
                width: "90%",
                height: "38.5px",
                border: "1px solid #0f1919",
                padding: "0px",
                margin: "1px",
              }}
            >
              play
            </Button>
          </Grid>


          <Grid container item xs={10} style={{
            display: "flex",
            alignItems: "center",
          }}>
            <Typography
              align="left"
              variant="h3"
              style={{
                color: "#171C21",
                padding: "4px",
              }}
            >
              move the game forward including starting a game, or starting the next round
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
      >
        <Grid container item xs={12}>
          <Grid container item justifyContent="center" xs={2}>
            <Button
              style={{
                background: "green",
                color: "black",
                width: "90%",
                height: "38.5px",
                border: "1px solid #0f1919",
                padding: "0px",
                margin: "1px",
              }}
            >
              pass
            </Button>
          </Grid>


          <Grid container item xs={10} style={{
            display: "flex",
            alignItems: "center",
          }}>
            <Typography
              align="left"
              variant="h3"
              style={{
                color: "#171C21",
                padding: "4px",
              }}
            >
              you can pass your turn if you cannot or do not want to go out. you cannot pass if somebody else has already gone out in the round.
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
      >
        <Grid container item xs={12}>
          <Grid container item justifyContent="center" xs={2}>
            <Button
              style={{
                background: "green",
                color: "black",
                width: "90%",
                height: "38.5px",
                border: "1px solid #0f1919",
                padding: "0px",
                margin: "1px",
              }}
            >
              play
            </Button>
          </Grid>


          <Grid container item xs={10} style={{
            display: "flex",
            alignItems: "center",
          }}>
            <Typography
              align="left"
              variant="h3"
              style={{
                color: "#171C21",
                padding: "4px",
              }}
            >
              you can play your turn if you can make one or more words with the available cards. if you have picked up a card, you must discard a card.
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
      >
        <Grid container item xs={12}>
          <Grid container item justifyContent="center" xs={2}>
            <IconButton
              style={{
                background: "orange",
                color: "black",
                width: "40px",
                height: "40px",
                border: "1px solid #0f1919",
                padding: "0px",
                margin: "1px",
              }}
            >
              <KeyboardArrowUpIcon />
            </IconButton>
          </Grid>


          <Grid container item xs={10} style={{
            display: "flex",
            alignItems: "center",
          }}>
            <Typography
              align="left"
              variant="h3"
              style={{
                color: "#171C21",
                padding: "4px",
              }}
            >
              this makes a word from the 2 or more cards that you have played
            </Typography>
          </Grid>
        </Grid>
      </Grid>


      <Grid
        container
        item
        xs={12}
      >
        <Grid container item xs={12}>
          <Grid container item justifyContent="center" xs={2}>
            <IconButton
              style={{
                background: "orange",
                color: "black",
                width: "40px",
                height: "40px",
                border: "1px solid #0f1919",
                padding: "0px",
                margin: "1px",
              }}
            >
              <KeyboardArrowDownIcon />
            </IconButton>
          </Grid>


          <Grid container item xs={10} style={{
            display: "flex",
            alignItems: "center",
          }}>
            <Typography
              align="left"
              variant="h3"
              style={{
                color: "#171C21",
                padding: "4px",
              }}
            >
              removes guess
            </Typography>
          </Grid>
        </Grid>
      </Grid>


      <Grid
        container
        item
        xs={12}
      >
        <Grid container item xs={12}>
          <Grid container item justifyContent="center" xs={2}>
            <IconButton
              style={{
                background: "orange",
                color: "black",
                width: "40px",
                height: "40px",
                border: "1px solid #0f1919",
                padding: "0px",
                margin: "1px",
              }}
            >
              <KeyboardDoubleArrowDownIcon />
            </IconButton>
          </Grid>

          <Grid container item xs={10} style={{
            display: "flex",
            alignItems: "center",
          }}>
            <Typography
              align="left"
              variant="h3"
              style={{
                color: "#171C21",
                padding: "4px",
              }}
            >
              removes all guesses
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
      >
        <Grid container item xs={12}>
          <Grid container item justifyContent="center" xs={2}>
            <IconButton
              style={{
                background: "orange",
                color: "black",
                width: "40px",
                height: "40px",
                border: "1px solid #0f1919",
                padding: "0px",
                margin: "1px",
              }}
            >
              <ShuffleIcon />
            </IconButton>
          </Grid>

          <Grid container item xs={10} style={{
            display: "flex",
            alignItems: "center",
            padding: "4px",
          }}>
            <Typography
              align="left"
              variant="h3"
              style={{
                color: "#171C21",
                padding: "4px",
              }}
            >
              rearranges the cards available to you
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
      >
        <Grid container item xs={12}>
          <Grid container item justifyContent="center" xs={2}>
            <Button
              style={{
                background: "green",
                color: "black",
                width: "90%",
                height: "38.5px",
                border: "1px solid #0f1919",
                padding: "0px",
                margin: "1px",
              }}
            >
              confirm
            </Button>
          </Grid>


          <Grid container item xs={10} style={{
            display: "flex",
            alignItems: "center",
            padding: "4px",
          }}>
            <Typography
              align="left"
              variant="h3"
              style={{
                color: "#171C21",
                padding: "4px",
              }}
            >
              confirm playing a word
            </Typography>
          </Grid>
        </Grid>
      </Grid>


      <Grid
        container
        item
        xs={12}
      >
        <Grid container item xs={12}>
          <Grid container item justifyContent="center" xs={2}>
            <Button
              style={{
                background: "red",
                color: "black",
                width: "40px",
                height: "40px",
                border: "1px solid #0f1919",
                padding: "0px",
                margin: "1px",
              }}
            >
              cancel
            </Button>
          </Grid>


          <Grid container item xs={10} style={{
            display: "flex",
            alignItems: "center",
            padding: "4px",
          }}>
            <Typography
              align="left"
              variant="h3"
              style={{
                color: "#171C21",
                padding: "4px",
              }}
            >
              cancel a pass confirmation. cancel a challenged word, or removes a word that you have guessed
            </Typography>
          </Grid>
        </Grid>
      </Grid>



      <Grid
        container
        item
        xs={12}
      >
        <Grid container item xs={12}>
          <Grid container item justifyContent="center" xs={2}>
            <IconButton
              style={{
                background: "lightgreen",
                color: "black",
                width: "40px",
                height: "40px",
                border: "1px solid #0f1919",
                padding: "0px",
                margin: "1px",
              }}
            >
              <RefreshIcon />
            </IconButton>
          </Grid>
          <Grid container item xs={10} style={{
            display: "flex",
            alignItems: "center",
            padding: "4px",
          }}>
            <Typography
              align="left"
              variant="h3"
              style={{
                color: "#171C21",
                padding: "4px",
              }}
            >
              refreshes the game or list
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
      >
        <Grid container item xs={12}>
          <Grid container item justifyContent="center" xs={2}>
            <IconButton
              style={{
                background: "lightgreen",
                color: "black",
                width: "40px",
                height: "40px",
                border: "1px solid #0f1919",
                padding: "0px",
                margin: "1px",
              }}
            >
              <AddIcon />
            </IconButton>
          </Grid>

          <Grid container item xs={10} style={{
            display: "flex",
            alignItems: "center",
            padding: "4px",
          }}>
            <Typography
              align="left"
              variant="h3"
              style={{
                color: "#171C21",
                padding: "4px",
              }}
            >
              creates a new game
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
      >
        <Grid container item xs={12}>
          <Grid container item justifyContent="center" xs={2}>
            <IconButton
              style={{
                background: "green",
                color: "black",
                width: "40px",
                height: "40px",
                border: "1px solid #0f1919",
                padding: "0px",
                margin: "1px",
              }}
            >
              <PersonIcon />
            </IconButton>
          </Grid>

          <Grid container item xs={10} style={{
            display: "flex",
            alignItems: "center",
          }}>
            <Typography
              align="left"
              variant="h3"
              style={{
                color: "#171C21",
                padding: "4px",
              }}
            >
              view and edit your profile details
            </Typography>
          </Grid>
        </Grid>
      </Grid>


      <Grid
        container
        item
        xs={12}
      >
        <Grid container item xs={12}>
          <Grid container item justifyContent="center" xs={2}>
            <IconButton
              style={{
                background: "lightgreen",
                color: "black",
                width: "40px",
                height: "40px",
                border: "1px solid #0f1919",
                padding: "0px",
                margin: "1px",
              }}
            >
              <HomeIcon />
            </IconButton>
          </Grid>
          <Grid container item xs={10} style={{
            display: "flex",
            alignItems: "center",
            padding: "4px",
          }}>
            <Typography
              align="left"
              variant="h3"
              style={{
                color: "#171C21",
                padding: "4px",
              }}
            >
              lists all of your games
            </Typography>
          </Grid>
        </Grid>
      </Grid>


      <Grid
        container
        item
        xs={12}
      >
        <Grid container item xs={12}>
          <Grid container item justifyContent="center" xs={2}>
            <IconButton
              style={{
                background: "lightgreen",
                color: "black",
                width: "40px",
                height: "40px",
                border: "1px solid #0f1919",
                padding: "0px",
                margin: "1px",
              }}
            >
              <NavigateNextIcon />
            </IconButton>
          </Grid>

          <Grid container item xs={10} style={{
            display: "flex",
            alignItems: "center",
            padding: "4px",
          }}>
            <Typography
              align="left"
              variant="h3"
              style={{
                color: "#171C21",
                padding: "4px",
              }}
            >
              displays the next game in your list of games. It will show the next game that is your turn
            </Typography>
          </Grid>
        </Grid>
      </Grid>



      <Grid
        container
        item
        xs={12}
      >
        <Grid container item xs={12}>
          <Grid container item justifyContent="center" xs={2}>
            <IconButton
              style={{
                background: "lightgreen",
                color: "black",
                width: "40px",
                height: "40px",
                border: "1px solid #0f1919",
                padding: "0px",
                margin: "1px",
              }}
            >
              <PersonAddIcon />
            </IconButton>
          </Grid>

          <Grid container item xs={10} style={{
            display: "flex",
            alignItems: "center",
            padding: "4px",
          }}>
            <Typography
              align="left"
              variant="h3"
              style={{
                color: "#171C21",
                padding: "4px",
              }}
            >
              add friend to new game
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
      >
        <Grid container item xs={12}>
          <Grid container item justifyContent="center" xs={2}>
            <IconButton
              style={{
                background: "pink",
                color: "black",
                width: "40px",
                height: "40px",
                border: "1px solid #0f1919",
                padding: "0px",
                margin: "1px",
              }}
            >
              <PersonRemoveAlt1Icon />
            </IconButton>
          </Grid>

          <Grid container item xs={10} style={{
            display: "flex",
            alignItems: "center",
          }}>
            <Typography
              align="left"
              variant="h3"
              style={{
                color: "#171C21",
                padding: "4px",
              }}
            >
              remove friend from new game
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
      >
        <Grid container item xs={12}>
          <Grid container item justifyContent="center" xs={2}>
            <IconButton
              style={{
                background: "orange",
                color: "black",
                width: "40px",
                height: "40px",
                border: "1px solid #0f1919",
                padding: "0px",
                margin: "1px",
              }}
            >
              <ThumbUpIcon />
            </IconButton>
          </Grid>


          <Grid container item xs={10} style={{
            display: "flex",
            alignItems: "center",
          }}>
            <Typography
              align="left"
              variant="h3"
              style={{
                color: "#171C21",
                padding: "4px",
              }}
            >
              you think that the challenged word is a word
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
      >
        <Grid container item xs={12}>
          <Grid container item justifyContent="center" xs={2}>
            <IconButton
              style={{
                background: "orange",
                color: "black",
                width: "40px",
                height: "40px",
                border: "1px solid #0f1919",
                padding: "0px",
                margin: "1px",
              }}
            >
              <ThumbDownIcon />
            </IconButton>
          </Grid>


          <Grid container item xs={10} style={{
            display: "flex",
            alignItems: "center",
          }}>
            <Typography
              align="left"
              variant="h3"
              style={{
                color: "#171C21",
                padding: "4px",
              }}
            >
              you think that the challenged word is not a word
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
      >
        <Grid container item xs={12}>
          <Grid container item justifyContent="center" xs={2}>
            <Button
              style={{
                background: "red",
                color: "black",
                width: "40px",
                height: "40px",
                border: "1px solid #0f1919",
                padding: "0px",
                margin: "1px",
              }}
            >
              cancel challenge
            </Button>
          </Grid>


          <Grid container item xs={10} style={{
            display: "flex",
            alignItems: "center",
            padding: "4px",
          }}>
            <Typography
              align="left"
              variant="h3"
              style={{
                color: "#171C21",
                padding: "4px",
              }}
            >
              you can change you mind about challenging a word. you can only cancel a challenge if you have not already voted on the word
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
      >
        <Grid container item xs={12}>
          <Grid container item justifyContent="center" xs={2}>
            <IconButton
              style={{
                background: "green",
                color: "black",
                width: "40px",
                height: "40px",
                border: "1px solid #0f1919",
                padding: "0px",
                margin: "1px",
              }}
            >
              <ArrowForwardIcon />
            </IconButton>
          </Grid>


          <Grid container item xs={10} style={{
            display: "flex",
            alignItems: "center",
          }}>
            <Typography
              align="left"
              variant="h3"
              style={{
                color: "#171C21",
                padding: "4px",
              }}
            >
              post you chat message to the game's players
            </Typography>
          </Grid>
        </Grid>
      </Grid>

    </Grid>


  );
};

export default HowToPlay;
