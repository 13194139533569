import React from "react";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import { ICard } from "../../Types/types";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import _ from "lodash";

export type RoundsScoreGuessProps = {
  cards: ICard[];
  guesser: string;
  isDiscard: boolean;
  validWord: boolean;
  canChallenge: boolean;
  challenge: (word: ICard[], susPlayer: string) => void;
};
export const RoundsScoreGuess: React.FC<RoundsScoreGuessProps> = ({
  cards,
  guesser,
  isDiscard,
  validWord,
  canChallenge,
  challenge,
}: RoundsScoreGuessProps) => {
  const guessValue = _.sumBy(cards, (x) => x.value);

  const challengeWord = () => {
    challenge(cards, guesser);
  };

  return (
    <Grid
      display="flex"
      container
      direction={"row"}
      style={{
        background: isDiscard || !validWord ? "pink" : "lightblue",
        padding: "0px",
        border: "0px solid #0f1919",
        height: "100%",
      }}
    >
      <Grid
        display="flex"
        justifyContent="center"
        style={{
          paddingTop: "3px",
          minWidth: "40px",
          marginLeft: "5px",
          marginRight: "5px",
        }}
      >
        {cards.map((card: ICard, i) => (
          <Grid
            key={"k-sgc" + i}
            style={{
              background: "transparent",
              margin: "2px",
              padding: "1px",
            }}
          >
            <Typography
              align="center"
              style={{
                fontSize: "18px",
                color: "#171C21",
                lineHeight: "18px",
                fontWeight: "250",
                width: "100%",
              }}
            >
              {card.letters}
            </Typography>
          </Grid>
        ))}
      </Grid>

      <Grid display="flex" style={{ minWidth: "20px" }}>
        <Grid
          style={{ paddingTop: "4px", marginLeft: "2px", marginRight: "8px" }}
        >
          <Typography
            align="center"
            style={{
              fontSize: "15px",
              color: "#171C21",
              lineHeight: "15px",
              fontWeight: "500",
              width: "100%",
            }}
          >
            {validWord ? guessValue : guessValue * -1}
          </Typography>
          <Typography
            align="center"
            style={{
              fontSize: "10px",
              color: "#171C21",
              lineHeight: "15x",
              fontWeight: "340",
              width: "100%",
              marginTop: "-5px",
            }}
          >
            points
          </Typography>
        </Grid>
      </Grid>

      {validWord && canChallenge && (
        <IconButton
          style={{
            background: "orange",
            color: "black",
            width: "28px",
            height: "28px",
            border: "1px solid orange",
            padding: "0px",
            marginTop: "1px",
            marginRight: "4px",
          }}
          onClick={(e) => {
            challengeWord();
          }}
        >
          <PriorityHighIcon />
        </IconButton>
      )}
    </Grid>
  );
};

export default RoundsScoreGuess;
