import React, { useEffect, useState } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import _ from "lodash";

type SignInProps = {
  login: (email: string) => void;
};

export function sanatisePlayerName(name: string): string {
  return name.replace(/ /g, "").toLowerCase();
}

const SignIn: React.FC<SignInProps> = ({ login }) => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState<string | undefined>(undefined);

  const doLogin = () => {
    const emailRegexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    if (email.length === 0) setMessage("email address is required");
    else if (!emailRegexp.test(email)) setMessage("invalid email address");
    else {
      setMessage(undefined);
      login(email.toLowerCase());
    }
  };

  return (
    <Grid
      container
      display="flex"
      justifyContent="center"
      style={{
        border: "0px solid #0f1919",
        background: "white",
      }}
    >
      <Grid
        container
        item
        xs={12}
        justifyContent={"center"}
        style={{
          background: "transparent",
          border: "0px solid #0f1919",
          marginTop: "4px",
          marginBottom: "4px",
        }}
      >
        <Grid
          container
          item
          xs={12}
          justifyContent={"center"}
          style={{
            background: "transparent",
            border: "0px solid #0f1919",
            marginTop: "4px",
            marginBottom: "4px",
          }}
        >
          <Box
            component="img"
            sx={{
              height: 233,
              maxHeight: { xs: 233, md: 167 },
            }}
            alt="Quidbone logo"
            src={require("../../images/logo512.jpg")}
          />
        </Grid>
        <Typography
          align="center"
          style={{
            fontSize: "22px",
            width: "100%",
            fontWeight: "550",
          }}
        >
          continue with your email
        </Typography>
      </Grid>

      {message && (
        <Grid
          container
          item
          xs={12}
          justifyContent={"center"}
          style={{
            background: "transparent",
            border: "0px solid #0f1919",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <Typography
            align="center"
            variant="h1"
            style={{
              color: "red",
              width: "100%",
              fontWeight: 350,
            }}
          >
            {message}
          </Typography>
        </Grid>
      )}

      <Grid
        container
        item
        xs={12}
        justifyContent={"center"}
        style={{
          background: "transparent",
          border: "0px solid #0f1919",
          width: "100%",
          marginTop: "4px",
          marginBottom: "4px",
          marginLeft: "16px",
          marginRight: "16px",
        }}
      >
        <TextField
          id="outlined-basic"
          label="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          variant="outlined"
          style={{
            background: "white",
            width: "100%",
            padding: "0px",
          }}
        />
      </Grid>

      <Grid
        container
        item
        xs={12}
        display="flex"
        justifyContent="center"
        style={{
          background: "transparent",
          border: "0px solid #0f1919",
          marginTop: "24px",
          marginBottom: "4px",
          marginLeft: "16px",
          marginRight: "16px",
        }}
      >
        <Button
          style={{
            background: "green",
            color: "white",
            height: "38.5px",
            width: "100%",
            border: "1px solid #0f1919",
            padding: "8px",
            marginTop: "0px",
          }}
          onClick={doLogin}
        >
          play
        </Button>
      </Grid>
    </Grid>
  );
};

export default SignIn;
