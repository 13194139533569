import React from "react";
import { Grid, Typography } from "@mui/material";
import { IFriend } from "../../Types/types";
import _ from "lodash";
import Friend from "../Freind";

export type FriendsProps = {
  friends: IFriend[];
  addFriend: (friend: IFriend) => void;
  removeFriend: (friend: IFriend) => void;
  canBegin: boolean;
  allPlayersSelected: boolean;
};
export const Friends: React.FC<FriendsProps> = ({
  friends,
  addFriend,
  removeFriend,
  canBegin,
  allPlayersSelected,
}: FriendsProps) => {
  return (
    <Grid
      container item
      display="flex"
      justifyContent="center"
      style={{
        paddingTop: "4px",
        paddingBottom: "4px",
        minHeight: "55px",
        background: "transparent",
        margin: "0px",
        border: "0px solid #0f1919",
      }}
    >
      <Grid container item xs={12} justifyContent="center">
        <Typography
          align="center"
          variant="h1"
          style={{
            color: "#0f1919",
            margin: "4px",
          }}
        >
          create new game
        </Typography>
      </Grid>
      <Grid container item xs={12} justifyContent="center" style={{
        paddingTop: "0px",
        paddingBottom: "0px",
        minHeight: "55px",
        background: canBegin ? "lightgreen" : "lightyellow",
        width: "300px",
        height: "80px",
        margin: "4px",
        borderRadius: "4px",
        border: "1px solid #0f1919",
      }}>
        {friends
          .filter((x) => x.isSelected)
          .map((friend: IFriend, i) => (
            <Grid
              key={"k-gugu" + i}
              style={{
                background: "trsnparent",
                border: "0px solid #0f1919",
                margin: "4px",
              }}
            >
              <Friend
                friend={friend}
                addFriend={addFriend}
                removeFriend={removeFriend}
              />
            </Grid>
          ))}
        <Grid container item xs={12} justifyContent="center">
          {friends.filter(x => x.isSelected).length === 0 && (
            <Typography
              align="center"
              variant="h2"
              style={{
                color: "#0f1919",
                marginTop: "30px",
              }}
            >
              no friends selected
            </Typography>
          )}
        </Grid>
      </Grid>

      {!allPlayersSelected && (
        <Grid container item xs={12} justifyContent="center" >
          <Typography
            align="center"
            variant="h2"
            style={{
              color: "#0f1919",
              margin: "4px",
            }}
          >
            add your friends to the game
          </Typography>
        </Grid>

      )}
      {!allPlayersSelected && (
        <Grid container item xs={12} justifyContent="center" style={{
          paddingTop: "0px",
          paddingBottom: "0px",
          minHeight: "55px",
          background: "lightyellow",
          width: "300px",
          height: "80px",
          margin: "4px",
          borderRadius: "4px",
          border: "1px solid #0f1919",
        }}>
          {friends
            .filter((x) => !x.isSelected)
            .map((friend: IFriend, i) => (
              <Grid
                key={"k-gugu" + i}
                style={{
                  background: "trsnparent",
                  border: "0px solid #0f1919",
                  margin: "4px",
                }}
              >
                <Friend
                  friend={friend}
                  addFriend={addFriend}
                  removeFriend={removeFriend}
                />
              </Grid>
            ))}
        </Grid>


      )}
    </Grid>
  );
};

export default Friends;
