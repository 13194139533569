import React, { useEffect, useState } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import _ from "lodash";
import axios from "axios";



const Register: React.FC = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState<string | undefined>(undefined);


  const register = (name: string, email: string) => {
    const url = "/register";
    axios
      .post<string>(url, {
        name: name,
        email: email,
      })
      .then((res) => {
        if ((res.status = 200)) {
          const token = res.data as string;
          window.localStorage.setItem("token", token);
          window.localStorage.setItem("player", email.toLowerCase());
        } else {
          console.error(res.data as string);
        }
      }).catch(err => {
        console.error(err.toString());
      });
  };

  const doRegister = () => {

    const emailRegexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    const hasDigitRegex = /\d/;
    const minLengthRegex = /^.{3,}$/;
    const maaxLengthRegex = /^.{0,9}$/;

    if (email.length === 0) setMessage("your email is required");
    else if (!emailRegexp.test(email)) setMessage("your email in not an email address");
    else if (name.length === 0) setMessage("your name  is required");
    else if (!hasDigitRegex.test(name)) setMessage("your name must contain at least one digit");
    else if (!minLengthRegex.test(name)) setMessage("your name must be between 3 and 10 characters");
    else if (!maaxLengthRegex.test(name)) setMessage("your name must be between 3 and 10 characters");
    else {
      setMessage(undefined);
      register(name, email);
    }
  };

  return (
    <Grid
      container
      display="flex"
      justifyContent="center"
      style={{
        border: "0px solid #0f1919",
        background: "transparent",
      }}
    >
      <Grid
        container
        item
        xs={12}
        justifyContent={"center"}
        style={{
          background: "transparent",
          border: "0px solid #0f1919",
          marginTop: "4px",
          marginBottom: "4px",
        }}
      >
        <Grid
          container
          item
          xs={12}
          justifyContent={"center"}
          style={{
            background: "transparent",
            border: "0px solid #0f1919",
            marginTop: "4px",
            marginBottom: "4px",
          }}
        >
          <Box
            component="img"
            sx={{
              height: 233,
              maxHeight: { xs: 233, md: 167 },
            }}
            alt="Quidbone logo"
            src={require("../../images/logo512.jpg")}
          />
        </Grid>
        <Typography
          align="center"
          variant="h3"

        >
          enter an email address & your player name to play
        </Typography>
      </Grid>

      {message && (
        <Grid
          container
          item
          xs={12}
          justifyContent={"center"}
          style={{
            background: "transparent",
            border: "0px solid #0f1919",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <Typography
            align="center"
            variant="h1"
            style={{
              color: "red",
              width: "100%",
              fontWeight: 350,
            }}
          >
            {message}
          </Typography>
        </Grid>
      )}

      <Grid
        container
        item
        xs={12}
        justifyContent={"center"}
        style={{
          background: "transparent",
          border: "0px solid #0f1919",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <Typography
          align="center"
          variant="h3"

        >
          player names must be between 3 and 10 characters and contain at least one digit
        </Typography>
      </Grid>

      <Grid
        container
        item
        xs={12}
        justifyContent={"center"}
        style={{
          background: "transparent",
          border: "0px solid #0f1919",
          marginTop: "4px",
          marginBottom: "4px",
        }}
      >
        <TextField
          id="outlined-basic"
          label="Your email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          variant="outlined"
        />
      </Grid>

      <Grid
        container
        item
        xs={12}
        justifyContent={"center"}
        style={{
          background: "transparent",
          border: "0px solid #0f1919",
          marginTop: "4px",
          marginBottom: "4px",
        }}
      >
        <TextField
          id="outlined-basic"
          label="you player name"
          value={name}
          onChange={(event) => setName(event.target.value)}
          variant="outlined"
        />
      </Grid>

      <Grid
        item
        display="flex"
        justifyContent="center"
        style={{
          background: "transparent",
          border: "0px solid #0f1919",
          marginTop: "4px",
          marginBottom: "4px",
        }}
      >
        <Button
          disabled={true}
          style={{
            background: "gray",
            color: "white",
            width: "100%",
            height: "38.5px",
            border: "1px solid #0f1919",
            padding: "8px",
            marginTop: "0px",
          }}
          onClick={doRegister}
        >
          register
        </Button>
      </Grid>

      <Grid
        container
        item
        xs={12}
        justifyContent={"center"}
        style={{
          background: "transparent",
          border: "0px solid #0f1919",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <Typography
          align="center"
          variant="h3"
          style={{
            color: "red",
          }}
        >
          Quidbone is not currently accepting new players. Please check back soon.
        </Typography>
      </Grid>

      <Grid
        container
        item
        xs={12}
        justifyContent={"center"}
        style={{
          background: "transparent",
          border: "0px solid #0f1919",
          marginTop: "40px",
          marginBottom: "4px",
        }}
      >
        <Typography
          align="center"
          style={{
            color: "grey",
            fontSize: "10px",
            width: "100%",
            fontWeight: "550",
          }}
        >
          Quidbone &#174;  2024 &#169;
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Register;
