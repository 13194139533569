import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import _ from "lodash";
import LobbyGames from "../LobbyGames";
import { ILobby } from "../../Types/types";

import { useInterval } from 'usehooks-ts'

type LobbyProps = {
  lobby: ILobby;
  selectGame: (session: string) => void;
  createGame: () => void;
  refresh: () => void;
};

const Lobby: React.FC<LobbyProps> = ({ lobby, selectGame, createGame, refresh }) => {

  useInterval(() => {
    refresh();
  }, 3000
  );

  return (
    <Grid
      container
      display="flex"
      justifyContent="center"
      style={{
        background: "transparent",
        paddingTop: "4px",
        border: "0px solid #0f1919",
      }}
    >
      <Grid
        container
        item
        xs={12}
        style={{
          marginTop: "12px",
          marginBottom: "4px",
        }}
      >
        <Grid item xs={12}>
          <LobbyGames
            lobby={lobby}
            selectGame={selectGame}
            createGame={createGame}
          ></LobbyGames>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Lobby;
