import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import _ from "lodash";

export type RoundStatsRowProps = {
  title: string;
  wins: number;
  losses: number;
  lbs: number;
  mbs: number;
};
export const RoundStatsRow: React.FC<RoundStatsRowProps> = ({
  title,
  wins,
  losses,
  lbs,
  mbs,
}: RoundStatsRowProps) => {

  return (
    <Grid
      container
      item
      xs={12}
    >
      <Grid container item xs={12}>
        <Grid
          container item xs={4}
          style={{
            background: "trsnparent",
            border: "0px solid #0f1919",
          }}
        >
          <Typography
            align="center"
            variant="h1"
            style={{
              color: "#0f1919",
              width: "100%",
            }}
          >
            {Number.isNaN((wins * 100) / (wins + losses)) ? 0 : ((wins * 100) / (wins + losses)).toFixed(0)} %
          </Typography>
          {/* <Typography
            align="center"
            variant="h3"
            style={{
              color: "#0f1919",
              width: "100%",
            }}
          >
            wins
          </Typography> */}
        </Grid>

        <Grid
          container item xs={4}
          style={{
            background: "trsnparent",
            border: "0px solid #0f1919",
          }}
        >
          <Typography
            align="center"
            variant="h1"
            style={{
              color: "purple",
              width: "100%",
            }}
          >
            {Number.isNaN((lbs * 100) / (wins + losses)) ? 0 : ((lbs * 100) / (wins + losses)).toFixed(0)} %
          </Typography>
          {/* <Typography
            align="center"
            variant="h3"
            style={{
              color: "purple",
              width: "100%",
            }}
          >
            longbones
          </Typography> */}
        </Grid>

        <Grid
          container item xs={4}
          style={{
            background: "trsnparent",
            border: "0px solid #0f1919",
          }}
        >
          <Typography
            align="center"
            variant="h1"
            style={{
              color: "darkorange",
              width: "100%",
            }}
          >
            {Number.isNaN((mbs * 100) / (wins + losses)) ? 0 : ((mbs * 100) / (wins + losses)).toFixed(0)} %
          </Typography>
          {/* <Typography
            align="center"
            variant="h3"
            style={{
              color: "darkorange",
              width: "100%",
            }}
          >
            multibones
          </Typography> */}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RoundStatsRow;
