import React, { useState } from "react";
import { Button, Grid, Typography } from "@mui/material";

import {
  IAvatar,
  ICard,
  IPlayerRoundScore,
  IPlayRequest,
} from "../../Types/types";
import Play from "../Play";
import _ from "lodash";

import RoundSummary from "../RoundSummary";
import Avatars from "../Avatars";

type TutorialStep4Props = {
  playerName: string;
  stepNumber: number;
  isLastStep: boolean;
  progressTutorial: (nextStep: number | undefined) => void;

};

const TutorialStep4: React.FC<TutorialStep4Props> = ({
  stepNumber,
  playerName,
  isLastStep,
  progressTutorial,
}) => {

  const [toggle, setToggle] = useState<boolean>(false);
  const [canProgress, setCanProgress] = useState<boolean>(false);
  const [h2Message, setH2Message] = useState<string | undefined>(undefined);
  const [avatars, setAvatars] = useState<IAvatar[]>([
    {
      initial: playerName[0].toUpperCase(),
      isTurn: true,
      isPassed: false,
      isOut: false,
    },
    {
      initial: "O",
      isTurn: false,
      isPassed: false,
      isOut: false,
    }
  ]);
  const [hand, setHand] = useState<ICard[]>([
    { letters: "B", value: 7, isSelected: false },
    { letters: "O", value: 2, isSelected: false },
    { letters: "N", value: 5, isSelected: false },
    { letters: "U", value: 4, isSelected: false },
    { letters: "S", value: 3, isSelected: false },
  ],);
  const [pickup, setPickup] = useState<ICard | undefined>({ letters: "S", value: 3, isSelected: false },);
  const [additionalCard, setAdditionalCard] = useState<ICard | undefined>(undefined);
  const [roundScores, setRoundScores] = useState<IPlayerRoundScore[]>(
    [
      {
        player: 'oppponent',
        score: 20,
        duds: [],
        words: [{
          cards: [
            { letters: "L", value: 5, isSelected: false },
            { letters: "O", value: 2, isSelected: false },
            { letters: "N", value: 3, isSelected: false },
            { letters: "G", value: 4, isSelected: false },
          ],
          word: "LONG",
          validWord: true,
          canChallenge: false,
        }],
        lb: true,
        mb: false,
        mbDeboned: undefined,
        lbDeboned: undefined,
        notAWord: false,
      },
    ]);
  const [canDraw, setCanDraw] = useState<boolean>(true);
  const [isOut, setIsOut] = useState<boolean>(false);

  const reset = () => {
    setToggle(!toggle);
    setAvatars([{
      initial: playerName[0].toUpperCase(),
      isTurn: true,
      isPassed: false,
      isOut: false,
    },
    {
      initial: "O",
      isTurn: false,
      isPassed: false,
      isOut: false,
    }]);
    setHand([
      { letters: "B", value: 7, isSelected: false },
      { letters: "O", value: 2, isSelected: false },
      { letters: "N", value: 5, isSelected: false },
      { letters: "U", value: 4, isSelected: false },
      { letters: "S", value: 3, isSelected: false },
    ],)
    setPickup({ letters: "P", value: 7, isSelected: false });
    setAdditionalCard(undefined);
    setCanProgress(false);
    setIsOut(false);
    setRoundScores([
      {
        player: 'oppponent',
        score: 20,
        duds: [],
        words: [{
          cards: [
            { letters: "L", value: 5, isSelected: false },
            { letters: "O", value: 2, isSelected: false },
            { letters: "N", value: 3, isSelected: false },
            { letters: "G", value: 4, isSelected: false },
          ],
          word: "LONG",
          validWord: true,
          canChallenge: false,
        }],
        lb: true,
        mb: false,
        mbDeboned: undefined,
        lbDeboned: undefined,
        notAWord: false,
      },
    ]);
    setCanDraw(true);
  };

  const drawCardFromDeck = () => {
    const cards: ICard[] = [{ letters: 'A', value: 2, isSelected: false }, { letters: 'E', value: 2, isSelected: false }, { letters: 'I', value: 2, isSelected: false }, { letters: 'O', value: 2, isSelected: false }, { letters: 'U', value: 2, isSelected: false }];
    const randomIndex = Math.floor(Math.random() * cards.length);
    const ac = cards[randomIndex];
    setAdditionalCard(ac);
    setPickup(undefined);
    setHand([
      { letters: "B", value: 7, isSelected: false },
      { letters: "O", value: 2, isSelected: false },
      { letters: "N", value: 5, isSelected: false },
      { letters: "U", value: 4, isSelected: false },
      { letters: "S", value: 3, isSelected: false },
      ac
    ]);
  }

  const pickupCardFromPile = () => {
    setAdditionalCard(pickup);
    setPickup(undefined);
    setHand([
      { letters: "B", value: 7, isSelected: false },
      { letters: "O", value: 2, isSelected: false },
      { letters: "N", value: 5, isSelected: false },
      { letters: "U", value: 4, isSelected: false },
      { letters: "S", value: 3, isSelected: false },
      { letters: "S", value: 3, isSelected: false }
    ]);
  };

  const confirmPlay = (turn: IPlayRequest) => {
    if (turn.guesses.length === 1 && turn.guesses[0].cards.length === 5) {
      const rs = [
        {
          player: 'oppponent',
          score: 20,
          duds: [],
          words: [{
            cards: [
              { letters: "L", value: 5, isSelected: false },
              { letters: "O", value: 2, isSelected: false },
              { letters: "N", value: 3, isSelected: false },
              { letters: "G", value: 4, isSelected: false },
            ],
            word: "LONG",
            validWord: true,
            canChallenge: false,
          }],
          lb: false,
          mb: false,
          mbDeboned: undefined,
          lbDeboned: playerName,
          notAWord: false,
        },
        {
          player: playerName,
          score: 20,
          duds: [],
          words: turn.guesses,
          lb: true,
          mb: false,
          mbDeboned: undefined,
          lbDeboned: undefined,
          notAWord: false,
        },
      ];
      setAvatars([{
        initial: playerName[0].toUpperCase(),
        isTurn: false,
        isPassed: false,
        isOut: true,
      },
      {
        initial: "O",
        isTurn: true,
        isPassed: false,
        isOut: false,
      }]);
      setRoundScores(rs);
      setCanProgress(true);
      setCanDraw(false);
      setIsOut(true);
    }
  };

  const confirmRound = () => {
  };

  const nextGame = () => {
  };

  const challengeWord = () => {
  };


  return (
    <Grid
      container
      style={{
        border: "0px solid #0f1919",
        background: "#fafafa",
      }}
    >

      <Grid container item xs={12} style={{ padding: "4px", background: 'yellow' }}>
        <Grid container item>
          <Typography
            align="center"
            variant="h1"
            style={{
              width: "100%",
              margin: "4px",
            }}
          >
            lesson 4: make the longest word with your cards
          </Typography>
        </Grid>
      </Grid>

      <Grid container item xs={12} style={{ padding: "4px", background: 'yellow' }}>
        <Grid container item>
          <Typography
            align="center"
            variant="h3"
            style={{
              width: "100%",
              margin: "4px",
            }}
          >
            to complete the lesson, GO OUT with the longest word
          </Typography>
          <Typography
            align="center"
            variant="h3"
            style={{
              width: "100%",
              margin: "4px",
            }}
          >
            your opponent has played LONG, a 4 letter word
          </Typography>
          <Typography
            align="center"
            variant="h3"
            style={{
              width: "100%",
              margin: "4px",
            }}
          >
            you can win a Longbone bonus by playing a 5 letter word
          </Typography>
          <Typography
            align="center"
            variant="h3"
            style={{
              width: "100%",
              margin: "4px",
            }}
          >
            deboning is when you take a bonus away from another player
          </Typography>
        </Grid>
      </Grid>



      {h2Message && (
        <Grid display="flex" justifyContent="center" container item xs={12} style={{ width: "100%" }}>
          <Typography
            align="center"
            variant="h2"
            style={{
              width: "100%",
              color: "black",
              margin: "4px",
            }}
          >
            {h2Message}
          </Typography>
        </Grid>
      )}

      {roundScores && (
        <Grid item xs={12} style={{ padding: "0px", marginBottom: '4px', marginTop: '8px' }}>
          <Avatars
            avatars={avatars}
          />
        </Grid>
      )}

      <Grid item xs={12} style={{ padding: "5px" }}>
        <RoundSummary
          roundNumber={4}
          scores={roundScores}
          isRoundOver={true}
          challengeWord={challengeWord}
        />
      </Grid>


      {!isOut && (
        <Grid item xs={12} style={{ margin: "4px", border: "1px solid #0f1919", borderRadius: "4px" }}>
          <Play
            turnId={'tutorial'}
            roundAmount={7}
            hand={hand}
            isPlayersTurn={true}
            pickup={pickup}
            additionalCard={additionalCard}
            canPass={false}
            canDraw={canDraw}
            emptyState={false}
            reset={toggle}
            drawCardFromDeck={drawCardFromDeck}
            pickupCardFromPile={pickupCardFromPile}
            confirmPlay={confirmPlay}
            setMessage={setH2Message}
          />
        </Grid>
      )}

      {/* {!canProgress && word && discard && (

        <Grid container item xs={12} style={{ padding: "4px", background: 'yellow' }}>
          <Grid container item>
            <Typography
              align="center"
              variant="h3"
              style={{
                width: "100%",
                margin: "4px",
              }}
            >
              {word} may or may not be a real word. Your friends may challenge it later!
            </Typography>
            <Typography
              align="center"
              variant="h3"
              style={{
                width: "100%",
                margin: "4px",
              }}
            >
              You are discarding {discard} and the next player will be able to pick it up and use it in their next hand
            </Typography>
            <Typography
              align="center"
              variant="h3"
              style={{
                width: "100%",
                margin: "4px",
              }}
            >
              Now, all other players will be foreced to GO OUT, if they are ready or not
            </Typography>
          </Grid>
        </Grid>
      )}

      {!canProgress && !word && discard && (

        <Grid container item xs={12} style={{ padding: "4px", background: 'yellow' }}>
          <Grid container item>
            <Typography
              align="center"
              variant="h3"
              style={{
                width: "100%",
                margin: "4px",
              }}
            >
              You have not gone out, you have passed your turn.
            </Typography>
            <Typography
              align="center"
              variant="h3"
              style={{
                width: "100%",
                margin: "4px",
              }}
            >
              You are discarding {discard} and the next player will be able to pick it up and use it in their next hand
            </Typography>
            <Typography
              align="center"
              variant="h3"
              style={{
                width: "100%",
                margin: "4px",
              }}
            >
              Use the button below to try again
            </Typography>
          </Grid>
        </Grid>
      )} */}

      {canProgress && (

        <Grid container item xs={12} style={{ padding: "4px", background: 'yellow' }}>
          <Grid container item style={{ marginTop: "40px" }}>
            <Typography
              align="center"
              variant="h3"
              style={{
                width: "100%",
                margin: "4px",
              }}
            >
              Well done! You have gone out with the longest word
            </Typography>
            <Typography
              align="center"
              variant="h3"
              style={{
                width: "100%",
                margin: "4px",
              }}
            >
              you have gained the Multibone bonus and deboned your opponent
            </Typography>
            <Typography
              align="center"
              variant="h3"
              style={{
                width: "100%",
                margin: "4px",
              }}
            >
              A Longbone is a 10 point score bonus for your turn
            </Typography>
            <Typography
              align="center"
              variant="h3"
              style={{
                width: "100%",
                margin: "4px",
              }}
            >
              You can lose you Longbone bouns if another player plays a longer word than you in theie turn
            </Typography>
            <Typography
              align="center"
              variant="h3"
              style={{
                width: "100%",
                margin: "4px",
              }}
            >
              In lesson 5, you will see what happens when you cannot play all your cards
            </Typography>
          </Grid>
        </Grid>
      )}

      <Grid item xs={12} display="flex" justifyContent="center" style={{
        background: "yellow",
      }}>
        <Button
          style={{
            background: "green",
            color: "white",
            height: "38.5px",
            border: "1px solid #0f1919",
            padding: "8px",
            marginTop: "32px",
            marginBottom: "32px",
            marginRight: "4px",
          }}
          onClick={reset}
        >
          restart lesson {stepNumber}
        </Button>
        <Button
          disabled={!canProgress}
          style={{
            background: canProgress ? "green" : "grey",
            color: "white",
            border: "1px solid #0f1919",
            height: "38.5px",
            padding: "8px",
            marginTop: "32px",
            marginBottom: "32px",
            marginLeft: "4px",
          }}
          onClick={(e) => progressTutorial(isLastStep ? undefined : stepNumber + 1)}
        >
          {isLastStep ? 'finish tutorial' : 'next lesson'}
        </Button>
      </Grid>

    </Grid >
  );
};

export default TutorialStep4;
