import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { IFriend } from "../../Types/types";
import IconButton from "@mui/material/IconButton";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonRemoveAlt1Icon from '@mui/icons-material/PersonRemoveAlt1';
import _ from "lodash";
type FriendProps = {
  friend: IFriend;
  addFriend: (friend: IFriend) => void;
  removeFriend: (friend: IFriend) => void;
};

const Friend: React.FC<FriendProps> = ({
  friend,
  addFriend,
  removeFriend,
}: FriendProps) => {
  return (
    <Grid
      display="flex"
      justifyContent="center"
      container
      item
      style={{
        background: "transparent",
        margin: "4px",
        border: "0px solid #0f1919",
        textTransform: "none",
      }}
    >
      <Grid container item xs={6} justifyContent="center">
        <Typography
          align="center"
          variant="h3"
          style={{
            color: "#0f1919",
            marginTop: "4px",
          }}
        >
          {friend.playerName}
        </Typography>
      </Grid>

      <Grid container item xs={12} justifyContent="center">
        {!friend.isSelected && (
          <IconButton
            style={{
              background: "lightgreen",
              color: "black",
              width: "40px",
              height: "40px",
              border: "1px solid #0f1919",
              padding: "0px",
              margin: "1px",
            }}
            onClick={(e) => {
              addFriend(friend);
            }}
          >
            <PersonAddIcon />
          </IconButton>
        )}
      </Grid>

      <Grid container item xs={12} justifyContent="center">
        {friend.isSelected && (
          <IconButton
            style={{
              background: "pink",
              color: "black",
              width: "40px",
              height: "40px",
              border: "1px solid #0f1919",
              padding: "0px",
              margin: "1px",
            }}
            onClick={(e) => {
              removeFriend(friend);
            }}
          >
            <PersonRemoveAlt1Icon />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};

export default Friend;
