import React, { useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";

export type DrawCardProps = {
  drawCard: () => void;
};
export const DrawCard: React.FC<DrawCardProps> = ({ drawCard }) => {
  return (
    <Button
      style={{
        // background:
        //   "linear-gradient(          45deg,           #a9cff1 25%,           transparent 25%,           transparent 50%,           #a9cff1 50%,           #a9cff1 75%,           transparent 75%,           transparent          )",
        // backgroundSize: "5px 5px",
        // background: "linear-gradient(to left top, transparent 10px, #A9CFF1 10px)",
        width: "40px",
        height: "58px",
        border: "1px solid #0f1919",
        padding: "0px",
        margin: "0px",
        minWidth: 0,
        minHeight: 0,
      }}
      //disabled={!canInteractWith || isPlayed}

      onClick={(e) => {
        drawCard();
      }}
    >
      <Grid
        container
        item
        xs={12}
        justifyContent={"center"}
        style={{
          background: "transparent",
          border: "0px solid #0f1919",
          padding: "3px",
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          component="img"
          sx={{
            height: '50px',
            width: '36px',
          }}
          src={require("../../images/cardback.jpg")}
        />
      </Grid>

      {/* <Grid direction={"row"} container style={{}}>
        <Typography
          style={{
            fontSize: "30px",
            color: "black",
            lineHeight: "30px",
            fontWeight: "500",
            width: "100%",
          }}
        >
          ?
        </Typography>
      </Grid> */}
    </Button>
  );
};

export default DrawCard;
