import React, { useState } from "react";
import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { IChatMessage } from "../../Types/types";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import _ from "lodash";

export type ChatProps = {
  chatChips: string[];
  history: IChatMessage[];
  chat: (msg: string) => void;
};
export const Chat: React.FC<ChatProps> = ({
  history,
  chatChips,
  chat,
}: ChatProps) => {
  const [msg, setMsg] = useState("");

  const doChat = () => {
    if (msg && msg.length > 0) {
      chat(msg);
      setMsg('');
    }
  };

  const quickChat = (message: string) => {
    if (message && message.length > 0) {
      chat(message);
    }
  };

  return (
    <Grid
      container
      display="flex"
      direction={"row"}
      style={{
        width: "100%",
      }}
    >
      <Grid
        container
        item
        xs={12}
        direction={"row"}
        style={{
          background:
            "linear-gradient(0deg,  lightgray 25%,  transparent 25%,  transparent 50%,   lightgray 50%,   lightgray 75%,  transparent 75%,  transparent )",
          backgroundSize: "5px 5px",
          width: "100%",
          padding: "4px",
        }}
      >
        {chatChips
          .map((chatChip: string, i) => (
            <Chip
              key={"k-gugku" + i}
              label={chatChip} variant="outlined" style={{
                background: "white",
                border: "1px solid #0f1919",
                margin: "2px",
              }}
              onClick={(e) => quickChat(chatChip)}
            />
          ))}
      </Grid>

      <Grid
        container
        item
        justifyContent={"center"}
        xs={10}
        style={{
          background: "#fafafa",
          border: "0px solid #0f1919",
          width: "100%",
          marginTop: "4px",
          marginBottom: "4px",
          paddingLeft: "4px",
        }}
      >
        <TextField
          id="outlined-basic"
          label="Type a message"
          value={msg}
          style={{
            background: "white",
            width: "100%",
            padding: "0px",
          }}
          onChange={(event) => setMsg(event.target.value)}
          variant="outlined"
        />
      </Grid>

      <Grid
        container
        item
        justifyContent="center"
        xs={2}
      >
        <IconButton
          style={{
            background: "#235789",
            color: "#f4f3ee",
            width: "40px",
            height: "40px",
            border: "1px solid #0f1919",
            padding: "0px",
            marginTop: "8px",
          }}
          onClick={(e) => doChat()}
        >
          <ArrowForwardIcon />
        </IconButton>
      </Grid>

      <Grid
        display="flex"
        container
        item
        xs={12}
        justifyContent="center"
        style={{
          background: "transparent",
          width: "100%",
          paddingRight: "4px",
          paddingLeft: "4px",
        }}
      >
        {history.map((msg: IChatMessage, i) => (
          <Grid
            key={"k-ch" + i}
            container
            item
            direction={"row"}
            style={{
              width: "100%",
              margin: "4px",
            }}
          >
            <Grid>
              <Typography
                style={{
                  color: msg.myMessage ? "crimson" : "gray",
                  fontSize: "16px",
                  lineHeight: "20px",
                  fontWeight: "550",
                  padding: "0px",
                }}
              >
                {msg.player}
              </Typography>
            </Grid>

            <Grid>
              <Typography
                style={{
                  fontSize: "12px",
                  color: "#171C21",
                  lineHeight: "20px",
                  fontWeight: "350",
                  padding: "0px",
                  marginLeft: "28px",
                }}
              >
                {msg.date}
              </Typography>
            </Grid>

            <Grid
              container
              item
              xs={12}>
              <Typography
                style={{
                  fontSize: "18px",
                  color: "#171C21",
                  lineHeight: "25px",
                  fontWeight: "400",
                  marginLeft: "18px",
                  paddingTop: "4px",
                  paddingBottom: "12px",
                }}
              >
                {msg.message}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default Chat;
