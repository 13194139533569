import React from "react";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import { IAvatar } from "../../Types/types";
import _ from "lodash";

export type AvatarProps = {
  avatar: IAvatar;
};

export const Avatar: React.FC<AvatarProps> = ({
  avatar,
}: AvatarProps) => {
  return (
    <Grid container style={{
      width: "30px",
      height: "30px",
    }}>
      <Grid
        item
        container
        style={{
          background: avatar.isTurn ? "#82a000" : avatar.isOut ? "#ff8a8a" : "#ECEEFF",
          padding: "0px",
          height: "30px",
          border: '1px solid #081400',
        }}
      >
        <Typography
          align="center"
          style={{
            fontSize: "24px",
            color: (avatar.isOut || avatar.isTurn) ? "#f6fff8" : avatar.isOut ? "#ffedd3" : "#1C0000",
            lineHeight: "25px",
            fontWeight: "300",
            width: "100%",
            height: "25px",
            paddingTop: "3px",
          }}
        >
          {avatar.initial}
        </Typography>
      </Grid>

      {/* {avatar.isTurn && (
        <Grid container item xs={12} style={{ maxHeight: "6px", minHeight: "6px" }}>
          <Grid container item xs={12} style={{ maxHeight: "2px", minHeight: "2px" }}>
          </Grid >
          <Grid item style={{ margin: '0px', height: '6px', width: '100px', background: 'green' }} />
        </Grid >
      )} */}
    </Grid >
  )
};

export default Avatar;
