import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import {
  IGuess,
  IPlayerRoundScore,
  ICard,
} from "../../Types/types";
import _ from "lodash";
import RoundsScoreGuess from "../RoundsScoreGuess";
import DeboneOverlay from "../DeboneOverlay";

type RoundSummaryPlayerSummaryProps = {
  prs: IPlayerRoundScore;
  isRoundOver: boolean;
  challengeWord: (word: ICard[], susPlayer: string) => void;
};

const RoundSummaryPlayerSummary: React.FC<RoundSummaryPlayerSummaryProps> = ({
  prs,
  isRoundOver,
  challengeWord,
}: RoundSummaryPlayerSummaryProps) => {

  return (
    <Grid
      container
      item
      xs={12}
      style={{
        background: "transparent",
        border: "0px solid #0f1919",
        minHeight: "37px",
        padding: "0px",
        marginBottom: "4px",
      }}
    >
      <Grid
        // display="flex"
        // justifyContent="center"
        container
        item
        direction='row'
        xs={12}
        style={{
          margin: "0px",
        }}
      >
        <Grid container item
          style={{ paddingLeft: "12px" }}
          xs={5}
        >
          <Grid item
            xs={8}
          >
            <Typography
              align="center"
              variant="h1"
              style={{
                color: "#171C21",
                width: "80px",
                paddingTop: "4px",
              }}
            >
              {prs.player}
            </Typography>
          </Grid>

          <Grid
            container
            item
            xs={3}
            style={{
              width: "60px",
              margin: "0px",
            }}
          >
            <Typography
              align="center"
              variant="h4"
              style={{
                //   fontSize: "20px",
                color: "#171C21",
                lineHeight: "20px",
                //   fontWeight: "500",
                width: "100%",
              }}
            >
              {prs.score +
                (prs.lb && isRoundOver ? 10 : 0) +
                (prs.mb && isRoundOver ? 10 : 0)}
            </Typography>

            <Typography
              align="center"
              style={{
                fontSize: "10px",
                color: "#171C21",
                lineHeight: "10x",
                fontWeight: "240",
                width: "100%",
                marginTop: "-5px",
              }}
            >
              points
            </Typography>
          </Grid>
        </Grid>

        {(!prs.lb && !prs.lbDeboned) && (
          <Grid
            container
            justifyContent={"center"}
            item
            xs={3}
            style={{
              width: "100%",
              marginTop: "6px",
            }}
          >
            <Typography
              align="center"
              variant="h4"
              style={{
                color: "lightgray",
                width: "89.75px",
              }}
            >
              long bone
            </Typography>
          </Grid>
        )}

        {(prs.lb || prs.lbDeboned) && (
          <Grid
            container
            justifyContent={"center"}
            item
            xs={3}
            style={{
              width: "100%",
              margin: prs.lb && !isRoundOver ? "0px" : "6px",
            }}
          >
            {(prs.lb) && (
              <Typography
                align="center"
                variant="h4"
                style={{
                  color: "purple",
                  width: "89.75px",
                  // background: "red",
                  //marginRight: "4px",
                }}
              >
                {prs.lb ? "long bone " : ""}
                {prs.lb && !isRoundOver ? " potential" : ""}
              </Typography>
            )}

            {(prs.lbDeboned) && (
              <Grid
                container
                item
                style={{ width: "89.75px" }}
              >
                <DeboneOverlay player={prs.lbDeboned} bone={"long bone"} />
              </Grid>
            )}
          </Grid>
        )}

        {(!prs.mb && !prs.mbDeboned) && (
          <Grid
            container
            justifyContent={"center"}
            item
            xs={3}
            style={{
              width: "100%",
              marginTop: "6px",
            }}
          >
            <Typography
              align="center"
              variant="h4"
              style={{
                color: "lightgray",
                width: "89.75px",
              }}
            >
              multi bone
            </Typography>
          </Grid>
        )}

        {(prs.mb || prs.mbDeboned) && (
          <Grid
            justifyContent={"center"}
            container
            item
            xs={3}
            style={{
              width: "100px",
              //    background: "blue",
              margin: prs.mb && !isRoundOver ? "0px" : "6px",
            }}
          >
            {(prs.mb) && (
              <Typography
                align="center"
                variant="h4"
                style={{
                  color: !prs.mbDeboned ? "darkorange" : "gray",
                  width: "89.75px",
                  marginRight: "4px",
                }}
              >
                {prs.mb ? "multi bone " : ""}
                {prs.mb && !isRoundOver ? " potential" : ""}
              </Typography>
            )}

            {(prs.mbDeboned) && (
              <Grid
                container
                item
                style={{ width: "89.75px" }}
              >
                <DeboneOverlay player={prs.mbDeboned} bone={"multi bone"} />
              </Grid>
            )}

          </Grid>
        )}
      </Grid >

      <Grid
        container
        item
        justifyContent={"center"}
        xs={12}
        style={{
          background: "transparent",
          border: "0px solid #0f1919",
          padding: "0px",
          marginTop: "0px",
          width: "100%",
        }}
      >
        {prs.words.map((word: IGuess, i) => (
          <Grid
            key={"k-rsws" + i}
            item
            style={{
              background: "transparent",
              border: "1px solid #0f1919",
              padding: "0px",
              marginLeft: "4px",
              marginRight: "8px",
              marginTop: "4px",
              marginBottom: "4px",
              height: "32px",
            }}
          >
            <RoundsScoreGuess
              cards={word.cards}
              guesser={prs.player}
              isDiscard={false}
              validWord={word.validWord}
              canChallenge={word.canChallenge}
              challenge={challengeWord}
            />
          </Grid>
        ))}
        {prs.duds && prs.duds.length > 0 && (
          <Grid
            item
            style={{
              background: "transparent",
              border: "1px solid #0f1919",
              padding: "0px",
              marginLeft: "4px",
              marginTop: "4px",
              marginRight: "8px",
              height: "32px",
            }}
          >
            <RoundsScoreGuess
              cards={prs.duds}
              guesser={prs.player}
              isDiscard={true}
              validWord={false}
              canChallenge={false}
              challenge={challengeWord}
            />
          </Grid>
        )}
      </Grid>
    </Grid >

  );
};

export default RoundSummaryPlayerSummary;
