import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import {
  IGuess,
  IPlayerRoundScore,
  ICard,
} from "../../Types/types";
import _ from "lodash";
import RoundsScoreGuess from "../RoundsScoreGuess";

type EndGameRoundSummaryPlayerSummaryProps = {
  prs: IPlayerRoundScore;
  isGameOver: boolean;
  challengeWord: (word: ICard[], susPlayer: string) => void;
};

const EndGameRoundSummaryPlayerSummary: React.FC<EndGameRoundSummaryPlayerSummaryProps> = ({
  prs,
  isGameOver,
  challengeWord,
}: EndGameRoundSummaryPlayerSummaryProps) => {

  return (
    <Grid
      container
      item
      xs={12}
      style={{
        background: "transparent",
        border: "0px solid #0f1919",
        minHeight: "37px",
        padding: "0px",
        marginBottom: "4px",
      }}
    >
      <Grid
        // display="flex"
        // justifyContent="center"
        container
        item
        direction='row'
        xs={12}
        style={{
          margin: "0px",
        }}
      >
        <Grid container item
          // style={{ paddingLeft: "12px" }}
          xs={12}
        >
          <Grid container item
            xs={12}
          >
            <Typography
              align="center"
              variant="h2"
              style={{
                color: "#171C21",
                width: "100%",
                paddingTop: "4px",
              }}
            >
              {prs.player}
            </Typography>
          </Grid>
        </Grid>
      </Grid >

      <Grid
        container
        item
        justifyContent={"center"}
        xs={12}
        style={{
          background: "transparent",
          border: "0px solid #0f1919",
          padding: "0px",
          marginTop: "0px",
          width: "100%",
        }}
      >
        {prs.words.map((word: IGuess, i) => (
          <Grid
            key={"k-rsws" + i}
            item
            style={{
              background: "transparent",
              border: "1px solid #0f1919",
              padding: "0px",
              marginLeft: "4px",
              marginRight: "8px",
              marginTop: "4px",
              marginBottom: "4px",
              height: "32px",
            }}
          >
            <RoundsScoreGuess
              cards={word.cards}
              guesser={prs.player}
              isDiscard={false}
              validWord={word.validWord}
              canChallenge={word.canChallenge && !isGameOver}
              challenge={challengeWord}
            />
          </Grid>
        ))}
        {prs.duds && prs.duds.length > 0 && (
          <Grid
            item
            style={{
              background: "transparent",
              border: "1px solid #0f1919",
              padding: "0px",
              marginLeft: "4px",
              marginTop: "4px",
              marginRight: "8px",
              height: "32px",
            }}
          >
            <RoundsScoreGuess
              cards={prs.duds}
              guesser={prs.player}
              isDiscard={true}
              validWord={false}
              canChallenge={false}
              challenge={challengeWord}
            />
          </Grid>
        )}
      </Grid>
    </Grid >

  );
};

export default EndGameRoundSummaryPlayerSummary;
