import React, { useState } from "react";
import { Grid, IconButton, TextField, Typography } from "@mui/material";
import { IFriend } from "../../Types/types";
import _ from "lodash";
import AddIcon from '@mui/icons-material/Add';

export type ProfileFriendsProps = {
  friends: IFriend[];
  addFriend: (friend: string) => void;
  //  removeFriend: (friend: IFriend) => void;
};
export const ProfileFriends: React.FC<ProfileFriendsProps> = ({
  friends,
  addFriend,
  // removeFriend, 
}: ProfileFriendsProps) => {

  const [email, setEmail] = useState("");
  const [message, setMessage] = useState<string | undefined>(undefined);

  const doAddFriend = () => {

    const emailRegexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

    if (email.length === 0) setMessage("your email is required");
    else if (!emailRegexp.test(email)) setMessage("your email in not an email address");
    else {
      setMessage(undefined);
      addFriend(email);
      setEmail('');
    }

  };

  return (
    <Grid
      container item
      display="flex"
      justifyContent="center"
      style={{
        paddingTop: "0px",
        paddingBottom: "0px",
        minHeight: "55px",
        background: "trsnparent",
        margin: "0px",
        border: "0px solid #0f1919",
      }}
    >
      <Grid container item xs={12} >
        <Typography
          variant="h1"
          style={{
            color: "#0f1919",
            margin: "4px",
          }}
        >
          friends
        </Typography>
      </Grid>


      {friends.length === 0 && (
        <Grid container item xs={12} justifyContent="center" >
          <Typography
            align="center"
            variant="h1"
            style={{
              color: "#0f1919",
              margin: "4px",
            }}
          >
            no registered friends
          </Typography>
        </Grid>

      )}

      <Grid container item xs={12} justifyContent="center" style={{
        paddingTop: "0px",
        paddingBottom: "0px",
        width: "100%",
        borderRadius: "4px",
        border: "0px solid #0f1919",
      }}>
        {friends
          .map((friend: IFriend, i) => (
            <Grid
              key={"k-gugu" + i}
              container item xs={12}
              style={{
                background: "trsnparent",
                border: "0px solid #0f1919",
                margin: "4px",
              }}
            >
              <Grid
                container item xs={3}
                style={{
                  background: "trsnparent",
                  border: "0px solid #0f1919",
                }}
              >
                <Typography
                  align="center"
                  variant="h4"
                  style={{
                    color: "#0f1919",
                  }}
                >
                  {friend.playerName}

                </Typography>
              </Grid>
              <Grid
                container item xs={9}
                style={{
                  background: "trsnparent",
                  border: "0px solid #0f1919",
                }}
              >
                <Typography
                  align="center"
                  variant="h5"
                  style={{
                    color: "#0f1919",
                  }}
                >
                  {friend.identity}

                </Typography>
              </Grid>
            </Grid>
          ))}
      </Grid>

      <Grid container item xs={10} >
        <Grid container item xs={12} >
          <Typography
            variant="h2"
            style={{
              color: "#0f1919",
              margin: "4px",
              marginTop: "20px",
            }}
          >
            add new friend
          </Typography>
        </Grid>

        <Grid
          container
          item
          justifyContent={"center"}
          xs={10}
          style={{
            background: "transparent",
            border: "0px solid #0f1919",
            width: "100%",
            marginTop: "4px",
            marginBottom: "4px",
          }}
        >
          <TextField
            id="outlined-basic"
            label="what is your friend's email"
            value={email}
            style={{
              background: "white",
              width: "100%",
              padding: "0px",
            }}
            onChange={(event) => setEmail(event.target.value)}
            variant="outlined"
          />
        </Grid>

        <Grid
          container
          item
          justifyContent="center"
          xs={2}
        >
          <IconButton
            style={{
              background: "green",
              color: "white",
              width: "40px",
              height: "40px",
              border: "1px solid #0f1919",
              padding: "0px",
              marginTop: "8px",
            }}
            onClick={(e) => doAddFriend()}
          >
            <AddIcon />
          </IconButton>
        </Grid>
      </Grid>

      {message && (
        <Grid
          container
          item
          xs={12}
          justifyContent={"center"}
          style={{
            background: "transparent",
            border: "0px solid #0f1919",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <Typography
            align="center"
            variant="h1"
            style={{
              color: "red",
              width: "100%",
              fontWeight: 350,
            }}
          >
            {message}
          </Typography>
        </Grid>
      )}

    </Grid>
  );
};

export default ProfileFriends;
