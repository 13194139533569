import React, { useEffect, useState } from "react";
import { Button, Grid, IconButton, Typography } from "@mui/material";

import {
  IAvatar,
  ICard,
  IChallengeWordSummary,
  IPlayerRoundScore,
} from "../../Types/types";
import _ from "lodash";

import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import RoundSummary from "../RoundSummary";
import Challenge from "../Challenges";
import Avatars from "../Avatars";

type TutorialStep5Props = {
  playerName: string;
  stepNumber: number;
  isLastStep: boolean;
  progressTutorial: (nextStep: number | undefined) => void;

};

const TutorialStep6: React.FC<TutorialStep5Props> = ({
  stepNumber,
  playerName,
  isLastStep,
  progressTutorial,
}) => {

  const [toggle, setToggle] = useState<boolean>(false);
  const [avatars, setAvatars] = useState<IAvatar[]>([
    {
      initial: "O",
      isTurn: false,
      isPassed: false,
      isOut: true,
    }, {
      initial: playerName[0].toUpperCase(),
      isTurn: false,
      isPassed: false,
      isOut: true,
    }
  ]);
  const [canProgress, setCanProgress] = useState<boolean>(false);
  const [word, setWord] = useState<string | undefined>(undefined);
  const [roundScore, setRoundScore] = useState<IPlayerRoundScore[]>(
    [
      {
        player: 'oppponent',
        score: 19,
        duds: [],
        words: [{
          cards: [
            { letters: "D", value: 4, isSelected: false },
            { letters: "O", value: 2, isSelected: false },
            { letters: "D", value: 4, isSelected: false },
            { letters: "G", value: 5, isSelected: false },
            { letters: "Y", value: 4, isSelected: false },
          ],
          word: "DODGY",
          validWord: true,
          canChallenge: true,
        }],
        lb: false,
        mb: false,
        mbDeboned: undefined,
        lbDeboned: undefined,
        notAWord: false,
      },
      {
        player: playerName,
        score: 18,
        duds: [],
        words: [{
          cards: [
            { letters: "M", value: 5, isSelected: false },
            { letters: "U", value: 4, isSelected: false },
            { letters: "L", value: 3, isSelected: false },
            { letters: "T", value: 4, isSelected: false },
            { letters: "I", value: 2, isSelected: false },
          ],
          word: "MULTI",
          validWord: true,
          canChallenge: false,
        }],
        lb: false,
        mb: false,
        mbDeboned: undefined,
        lbDeboned: undefined,
        notAWord: false,
      },
    ]);
  const [challenges, setChallenges] = useState<IChallengeWordSummary[]>([]);

  const reset = () => {
    setToggle(!toggle);
    setCanProgress(false);
    setWord(undefined);
    setRoundScore(
      [
        {
          player: 'oppponent',
          score: 19,
          duds: [],
          words: [{
            cards: [
              { letters: "D", value: 4, isSelected: false },
              { letters: "O", value: 2, isSelected: false },
              { letters: "D", value: 4, isSelected: false },
              { letters: "G", value: 5, isSelected: false },
              { letters: "Y", value: 4, isSelected: false },
            ],
            word: "DODGY",
            validWord: true,
            canChallenge: true,
          }],
          lb: false,
          mb: false,
          mbDeboned: undefined,
          lbDeboned: undefined,
          notAWord: false,
        },
        {
          player: playerName,
          score: 18,
          duds: [],
          words: [{
            cards: [
              { letters: "M", value: 5, isSelected: false },
              { letters: "U", value: 4, isSelected: false },
              { letters: "L", value: 3, isSelected: false },
              { letters: "T", value: 4, isSelected: false },
              { letters: "I", value: 2, isSelected: false },
            ],
            word: "MULTI",
            validWord: true,
            canChallenge: false,
          }],
          lb: false,
          mb: false,
          mbDeboned: undefined,
          lbDeboned: undefined,
          notAWord: false,
        },
      ]);
  };

  const challengeWord = (word: ICard[], susPlayer: string) => {
    const rs = [
      {
        player: 'oppponent',
        score: 19,
        duds: [],
        words: [{
          cards: [
            { letters: "D", value: 4, isSelected: false },
            { letters: "O", value: 2, isSelected: false },
            { letters: "D", value: 4, isSelected: false },
            { letters: "G", value: 5, isSelected: false },
            { letters: "Y", value: 4, isSelected: false },
          ],
          word: "DODGY",
          validWord: false,
          canChallenge: false,
        }],
        lb: false,
        mb: false,
        mbDeboned: undefined,
        lbDeboned: undefined,
        notAWord: false,
      },
      {
        player: playerName,
        score: 18,
        duds: [],
        words: [{
          cards: [
            { letters: "M", value: 5, isSelected: false },
            { letters: "U", value: 4, isSelected: false },
            { letters: "L", value: 3, isSelected: false },
            { letters: "T", value: 4, isSelected: false },
            { letters: "I", value: 2, isSelected: false },
          ],
          word: "MULTI",
          validWord: true,
          canChallenge: false,
        }],
        lb: true,
        mb: false,
        mbDeboned: undefined,
        lbDeboned: undefined,
        notAWord: false,
      },
    ];
    const cha = [
      {
        challengeId: "1",
        challengerId: "p1",
        challengeeId: "p2",
        challenger: playerName,
        challengee: susPlayer,
        cards: word,
        value: 19,
        isResolved: false,
        isDismissed: false,
        canCancel: true,
        isChallenger: true,
        isChallengee: false,
        isWordOnePlayer: undefined,
        isWordAllPlayers: undefined,

      }
    ];

    setRoundScore(rs);
    setChallenges(cha);
    setWord(word.map((x) => x.letters).join(''));
    setCanProgress(true);
  };

  const confirmRound = () => {
  };
  const nextGame = () => {
  };
  const acknowledgeChallenge = (id: string) => {
    setCanProgress(true);
    setWord(undefined);
  };
  const cancelChallenge = (id: string) => {
    setCanProgress(false);
    setWord(undefined);
  };
  const dismissChallenge = (id: string) => {
    setCanProgress(false);
    setWord(undefined);
  };
  return (
    <Grid
      container
      style={{
        border: "0px solid #0f1919",
        background: "#fafafa",
      }}
    >

      <Grid container item xs={12} style={{ padding: "4px", background: 'yellow' }}>
        <Grid container item>
          <Typography
            align="center"
            variant="h1"
            style={{
              width: "100%",
              margin: "4px",
            }}
          >
            lesson 6: what to do when your opponent plays a dosgy word
          </Typography>
        </Grid>
      </Grid>

      <Grid container item xs={12} style={{ padding: "4px", background: 'yellow' }}>
        <Grid container item>
          <Typography
            align="center"
            variant="h3"
            style={{
              width: "100%",
              margin: "4px",
            }}
          >
            to complete the lesson, CHALLENGE your opponent's word
          </Typography>
          <Typography
            align="center"
            variant="h3"
            style={{
              width: "100%",
              margin: "4px",
            }}
          >
            your opponent has played DODGY and you do not think that is a word
          </Typography>
          <Typography
            align="center"
            variant="h3"
            style={{
              width: "100%",
              margin: "4px",
            }}
          >
            you can play any word you want. your friends may challenge a word that may not be agree as a group is a word. after a word is challenged, everybody will be asked if they think the word is valid. if any player decided the word is invalid, then you will score 0 points for the word. if all players decide it is a word then the challenging player will lose points to the value of the challenged word.
          </Typography>
          <Typography
            align="center"
            variant="h3"
            style={{
              width: "100%",
              margin: "4px",
            }}
          >
            you can change you mind about challenging a word. you can only cancel a challenge if you have not already voted on the word
          </Typography>
        </Grid>
      </Grid>


      <Grid container item xs={12} style={{ padding: "4px", background: 'yellow' }}>
        <Grid container item>
          <Typography
            align="center"
            variant="h3"
            style={{
              width: "100%",
              margin: "4px",
            }}
          >
            to complete the lesson, GO OUT with multiple words
          </Typography>
          <Typography
            align="center"
            variant="h3"
            style={{
              width: "100%",
              margin: "4px",
            }}
          >
            the following controls will be useful to know
          </Typography>

          <Grid
            container
            item
            xs={12}
          >
            <Grid container item xs={12}>
              <Grid container item justifyContent="center" xs={2}>
                <IconButton
                  style={{
                    background: "green",
                    color: "white",
                    width: "40px",
                    height: "40px",
                    border: "1px solid #0f1919",
                    padding: "0px",
                    margin: "1px",
                  }}
                  onClick={(e) => {
                  }}
                >
                  <PriorityHighIcon />
                </IconButton>
              </Grid>


              <Grid container item xs={10} style={{
                display: "flex",
                alignItems: "center",
                padding: "4px",
              }}>
                <Typography
                  align="left"
                  variant="h3"
                  style={{
                    color: "#171C21",
                    padding: "4px",
                  }}
                >
                  challenge the word
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
          >
            <Grid container item xs={12}>
              <Grid container item justifyContent="center" xs={2}>
                <IconButton
                  style={{
                    background: "orange",
                    color: "black",
                    width: "40px",
                    height: "40px",
                    border: "1px solid #0f1919",
                    padding: "0px",
                    margin: "1px",
                  }}
                >
                  <ThumbUpIcon />
                </IconButton>
              </Grid>


              <Grid container item xs={10} style={{
                display: "flex",
                alignItems: "center",
              }}>
                <Typography
                  align="left"
                  variant="h3"
                  style={{
                    color: "#171C21",
                    padding: "4px",
                  }}
                >
                  you think that the challenged word is a word
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
          >
            <Grid container item xs={12}>
              <Grid container item justifyContent="center" xs={2}>
                <IconButton
                  style={{
                    background: "orange",
                    color: "black",
                    width: "40px",
                    height: "40px",
                    border: "1px solid #0f1919",
                    padding: "0px",
                    margin: "1px",
                  }}
                >
                  <ThumbDownIcon />
                </IconButton>
              </Grid>


              <Grid container item xs={10} style={{
                display: "flex",
                alignItems: "center",
              }}>
                <Typography
                  align="left"
                  variant="h3"
                  style={{
                    color: "#171C21",
                    padding: "4px",
                  }}
                >
                  you think that the challenged word is not a word
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Typography
            align="center"
            variant="h3"
            style={{
              width: "100%",
              margin: "4px",
            }}
          >
            challenge that word!
          </Typography>

        </Grid>
      </Grid>

      {roundScore && (
        <Grid item xs={12} style={{ padding: "0px", marginBottom: '4px', marginTop: '8px' }}>
          <Avatars
            avatars={avatars}
          />
        </Grid>
      )}

      <Grid item xs={12} style={{ padding: "5px" }}>
        <RoundSummary
          roundNumber={6}
          scores={roundScore}
          isRoundOver={true}
          challengeWord={challengeWord}
        />
      </Grid>

      {word && (
        <Grid item xs={12} style={{ padding: "5px" }}>
          <Challenge
            challenges={challenges}
            acknowledgeChallenge={acknowledgeChallenge}
            cancelChallenge={cancelChallenge}
            dismissChallenge={dismissChallenge}
          />
        </Grid>
      )}

      {canProgress && (

        <Grid container item xs={12} style={{ padding: "4px", background: 'yellow' }}>
          <Grid container item style={{ marginTop: "40px" }}>
            <Typography
              align="center"
              variant="h3"
              style={{
                width: "100%",
                margin: "4px",
              }}
            >
              Well done! You have challenged a word
            </Typography>
            <Typography
              align="center"
              variant="h3"
              style={{
                width: "100%",
                margin: "4px",
              }}
            >
              ultimately, the group decided that the word was a word
            </Typography>
            <Typography
              align="center"
              variant="h3"
              style={{
                width: "100%",
                margin: "4px",
              }}
            >
              you and your friends can decide on what is a good word is
            </Typography>
            <Typography
              align="center"
              variant="h3"
              style={{
                width: "100%",
                margin: "4px",
              }}
            >
              In lesson 7, the final lesson will cover the rest of the game
            </Typography>

          </Grid>
        </Grid>
      )}

      <Grid item xs={12} display="flex" justifyContent="center" style={{
        background: "yellow",
      }}>
        <Button
          style={{
            background: "green",
            height: "38.5px",
            color: "white",
            border: "1px solid #0f1919",
            padding: "8px",
            marginTop: "32px",
            marginBottom: "32px",
            marginRight: "4px",
          }}
          onClick={reset}
        >
          restart lesson {stepNumber}
        </Button>
        <Button
          disabled={!canProgress}
          style={{
            background: canProgress ? "green" : "grey",
            height: "38.5px",
            color: "white",
            border: "1px solid #0f1919",
            padding: "8px",
            marginTop: "32px",
            marginBottom: "32px",
            marginLeft: "4px",
          }}
          onClick={(e) => progressTutorial(isLastStep ? undefined : stepNumber + 1)}
        >
          {isLastStep ? 'finish tutorial' : 'next lesson'}
        </Button>
      </Grid>

    </Grid >
  );
};

export default TutorialStep6;
