import React, { useRef, useEffect, useState } from "react";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import { ICard, IHand } from "../../Types/types";
import Card from "../Card";
import DrawCard from "../DrawCard";

export type HandProps = {
  cards: ICard[];
  guessingCards: ICard[];
  isPlayersTurn: boolean;
  canDraw: boolean;
  confirmed: boolean;
  pickup: ICard | undefined;
  emptyState: boolean;
  reset: boolean;

  playCard: (turn: ICard) => void;
  callDrawCardFromDeck: () => void;
  callPickupCardFromPile: () => void;
  shuffleCards: () => void;
  playWord: () => void;
};
export const Hand: React.FC<HandProps> = ({
  cards,
  guessingCards,
  isPlayersTurn,
  canDraw,
  confirmed,
  pickup,
  emptyState,
  reset,
  playCard,
  callDrawCardFromDeck,
  callPickupCardFromPile,
  shuffleCards,
  playWord,
}: HandProps) => {

  const [isDrawn, setIsDrawn] = useState<boolean>(false);

  const doCallPickupCardFromPile = () => {
    setIsDrawn(true);
    callPickupCardFromPile();
  };
  const doCallPickupCardFromDeck = () => {
    setIsDrawn(true);
    callDrawCardFromDeck();
  };

  useEffect(() => {
    setIsDrawn(false);
  }, [reset]);

  const ltrs = guessingCards.length === 0 ? '...' : guessingCards.map((c) => c.letters).join("");
  const btnMsg = emptyState ? '' : 'i am happy with ' + ltrs;
  //console.log('Hand.tsx: cards:', emptyState, ltrs, btnMsg);
  return (
    <Grid
      container
      display="flex"
      justifyContent="center"
      style={{
        paddingTop: "0px",
        background: "transparent",
        border: "0px solid #081400",
      }}
    >
      <Grid
        container
        style={{
          background: "transparent",
        }}
      >

        <Grid container item xs={12} justifyContent="center"
          style={{
            background: '#ffed4d',
            marginBottom: "0px",
          }}>

          <Grid
            container
            item
            xs={12}
            style={{
              justifyContent: "center",
              width: "100%",
              margin: "0px",
            }}
          >
            <Button
              disabled={guessingCards.length < 2}
              style={{
                background: guessingCards.length < 2 ? "#7bebf3" : "#a5c34f",
                color: guessingCards.length < 2 ? "#1f587a" : "#081400",
                width: "100%",
                height: "38.5px",
                borderTop: "1px solid #081400",
                borderBottom: "1px solid #081400",
                paddingTop: "0px",
                paddingBottom: "2px",
                margin: "0px",
                borderRadius: "0px",
                textTransform: "none",
              }}
              onClick={(e) => playWord()}
            >
              {btnMsg}
            </Button>
          </Grid>

          {cards.length > 0 && (
            <Grid container item xs={12} justifyContent="center"
              style={{
                paddingTop: "12px",
                marginBottom: "12px",
              }}>

              {cards.filter(c => (isPlayersTurn || (!isPlayersTurn && !c.isSelected))).map((card: ICard, i) => (
                <Grid
                  key={"k-hc" + i}
                  style={{
                    background: "transparent",
                    paddingBottom: "4px",
                    paddingTop: "12px",
                    marginLeft: "4px",
                    marginRight: "4px",
                    marginBottom: "4px",
                  }}
                >
                  <Card key={"k-c" + i} card={card} play={playCard} isSmall={false} />
                </Grid>
              ))}
            </Grid>
          )}
          {cards.length === 0 && (
            <Grid item xs={12} style={{ background: "transparent", height: '114px' }}>
            </Grid>
          )}

          <Grid
            container
            item
            xs={12}
            style={{
              justifyContent: "center",
              borderTop: "1px solid #081400",
              borderBottom: "0px solid #081400",
              width: "100%",
              margin: "0px",
            }}
          >
            <Button
              style={{
                background: "#a5c34f",
                color: "#081400",
                width: "100%",
                height: "38.5px",
                borderBottom: "1px solid #081400",
                paddingTop: "2px",
                paddingBottom: "2px",
                padding: "0px",
                margin: "0px",
                borderRadius: "0px",
                textTransform: "none",
              }}
              onClick={(e) => shuffleCards()}
            >
              {emptyState ? '' : 'shuffle'}
            </Button>
          </Grid>

        </Grid>
      </Grid>

      {isPlayersTurn && canDraw && !isDrawn && !confirmed && (
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          style={{
            background: "#ffed4d",
            paddingTop: "16px",
            paddingBottom: "16px",
            //marginTop: "16px",
          }}
        >
          <Grid container item xs={6} style={{ paddingRight: "4px" }}>
            {pickup && (
              <Grid container item justifyContent="right" direction={"row"}>
                <Card card={pickup} play={doCallPickupCardFromPile} isSmall={true} />
              </Grid>
            )}
          </Grid>
          <Grid container item xs={6} style={{ paddingLeft: "4px" }}>
            <Grid container item style={{ margin: "0px" }}>
              <Grid
                container
                item
                direction={"row"}
                style={{ display: "flex", alignItems: "end", margin: "0px" }}
              >
                <DrawCard drawCard={doCallPickupCardFromDeck} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default Hand;
