import React from "react";
import ErrorPage from "./ErrorPage";

export default class ErrorBoundary extends React.Component<
  any,
  { hasError: boolean; error?: Error }
> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error: Error };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    console.error("Uncaught error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <ErrorPage />
        </>
      );
    }

    return this.props.children;
  }
}
