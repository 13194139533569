import React, { useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { IGameSummary, IGameSummaryTotal } from "../../Types/types";
import _ from "lodash";



type GameScoreProps = {
  summary: IGameSummary;
  isGameOver: boolean;
};

const GameScore: React.FC<GameScoreProps> = ({
  summary,
  isGameOver,
}: GameScoreProps) => {
  const scores: IGameSummaryTotal[] = summary.totals.map((gameScore) => {
    return {
      player: gameScore.player,
      total: gameScore.total,
      highest: gameScore.highest,
      lbAmt: gameScore.lbAmt,
      mbAmt: gameScore.mbAmt,
    };
  });

  return (
    <Grid
      display="flex"
      justifyContent="center"
      container
      item
      style={{
        background: "transparent",
        margin: "0px",
        border: "0px solid #0f1919",
      }}
    >
      {/* <Grid container item xs={3} justifyContent="center">
        <Typography align="center" variant="h2">
          game score
        </Typography>
      </Grid> */}

      <Grid container item xs={12} justifyContent="center">
        {scores.map((res: IGameSummaryTotal, i) => (
          <Grid
            container
            item xs={4}
            key={"k-gsspss" + i}>
            <Grid
              container
              item
              justifyContent={"center"}
              direction={"row"}
              style={{
                width: "100%",
              }}
            >
              <Typography
                align="center"
                variant="h4"
                style={{
                  color: res.highest ? "green" : "#171C21",
                  fontWeight: res.highest ? 500 : 400,
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  marginTop: "4px",
                }}
              >
                {res.player} : {res.total}
              </Typography>
              <Typography
                align="center"
                variant="h6"
                style={{
                  color: "purple",
                  width: "100%",
                  marginTop: "4px",
                }}
              >
                {res.lbAmt} long bone{res.lbAmt !== 1 ? "s" : ""}
              </Typography>

              <Typography
                align="center"
                variant="h6"
                style={{
                  color: "darkorange",
                  width: "100%",
                  marginTop: "4px",
                }}
              >
                {res.mbAmt} multi bone{res.mbAmt !== 1 ? "s" : ""}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default GameScore;
