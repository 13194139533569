import React, { useState } from "react";
import { Button, Grid } from "@mui/material";
import _ from "lodash";
import { IFriend, IFriends, IGameSetup } from "../../Types/types";
import Friends from "../Friends";

type NewGameProps = {
  friends: IFriends;
  createGame: (setup: IGameSetup) => void;
  startGame: () => void;
  createAndStartGame: () => void;
  addFriend: (friend: IFriend) => void;
  removeFriend: (friend: IFriend) => void;
};

const NewGame: React.FC<NewGameProps> = ({
  friends,
  createAndStartGame,
  addFriend,
  removeFriend,
}) => {
  const canBegin = friends.friends.filter((friend) => friend.isSelected).length > 0;
  const allPlayersSelected = friends.friends.filter((friend) => !friend.isSelected).length === 0;

  return (
    <Grid
      container
      display="flex"
      justifyContent="center"
      style={{
        background: "transparent",
        paddingTop: "0px",
        border: "0px solid #0f1919",
      }}
    >
      <Grid
        container
        item
        xs={12}
        style={{
          margin: "4px",
        }}
      >
        <Grid container item xs={12}>
          <Friends
            friends={friends.friends}
            addFriend={addFriend}
            removeFriend={removeFriend}
            canBegin={canBegin}
            allPlayersSelected={allPlayersSelected}
          ></Friends>
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
        display="flex"
        justifyContent="center"
        style={{
          background: "transparent",
          border: "0px solid #0f1919",
          marginBottom: "04px",
        }}
      >
        <Button
          disabled={!canBegin}
          style={{
            background: canBegin ? "green" : "gray",
            width: "90%",
            height: "38.5px",
            color: "white",
            border: "1px solid #0f1919",
            padding: "8px",
            margin: "0px",
          }}
          onClick={createAndStartGame}
        >
          start game
        </Button>
      </Grid>
    </Grid >
  );
};

export default NewGame;
