import React, { useEffect, useState } from "react";
import { Button, Grid, IconButton, Typography } from "@mui/material";

import _ from "lodash";


type TutorialStep5Props = {
  stepNumber: number;
  isLastStep: boolean;
  progressTutorial: (nextStep: number | undefined) => void;

};

const TutorialStep7: React.FC<TutorialStep5Props> = ({
  stepNumber,
  isLastStep,
  progressTutorial,
}) => {

  const accent = (x: number, y: number) => {
    if (x < y) return 'lightgreen';
    if (x === y) return 'green';
    return 'lightgrey';
  };

  return (
    <Grid
      container
      style={{
        border: "0px solid #0f1919",
        background: "#fafafa",
      }}
    >

      <Grid container item xs={12} style={{ padding: "4px", background: 'yellow' }}>
        <Grid container item>
          <Typography
            align="center"
            variant="h1"
            style={{
              width: "100%",
              margin: "4px",
            }}
          >
            lesson 7: how do I win?
          </Typography>
        </Grid>
      </Grid>

      <Grid container item xs={12} style={{ padding: "4px", background: 'yellow' }}>
        <Grid container item>
          <Typography
            align="center"
            variant="h3"
            style={{
              width: "100%",
              margin: "4px",
            }}
          >
            a game consists of 8 rounds. the player with the most points after 8 rounds wins the game. you score points by making words from available letters. letters have different point values
          </Typography>
          <Typography
            align="center"
            variant="h3"
            style={{
              width: "100%",
              margin: "4px",
            }}
          >
            the first round starts with 3 dealt cards. each subsequent round is dealth an additional 1 card. the last round starts with 14 cards. in each round, you can also use a card of known value that represents the discarded card from the previous player, and an unseen card of unknown value that represents the deck of cards
          </Typography>
          <Typography
            align="center"
            variant="h3"
            style={{
              width: "100%",
              margin: "4px",
            }}
          >
            a word must be at least 2 cards long. you can make a word by using 2 or more cards from your hand. you can also use cards from your hand and the discard pile. you can also use cards from your hand and the discard pile and the cards that are already on the table
          </Typography>
          <Typography
            align="center"
            variant="h3"
            style={{
              width: "100%",
              margin: "4px",
            }}
          >
            the 8 rounds look like this
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        style={{
          border: "0px solid #0f1919",
          background: "#fafafa",
          marginTop: "24px",
        }}
      >
        {[3, 4, 5, 6, 7, 8, 9, 10]
          .map((round: number, i) => (

            <Grid
              container
              style={{
                border: "0px solid #0f1919",
                background: "#fafafa",
                marginBottom: "24px",
              }}
            >
              <Grid container item xs={12} style={{ maxHeight: "5px", minHeight: "5px" }}>
                <Grid container item style={{ width: "2%", background: 'transparent' }}></Grid>
                <Grid container item style={{ width: "12%", }}>
                  <Grid item style={{ margin: '1px', width: '100px', background: accent(3, round) }}>  </Grid>
                </Grid>
                <Grid container item style={{ width: "12%", }}>
                  <Grid item style={{ margin: '1px', width: '100px', background: accent(4, round) }}> </Grid>
                </Grid>
                <Grid container item style={{ width: "12%", }}>
                  <Grid item style={{ margin: '1px', width: '100px', background: accent(5, round) }}> </Grid>
                </Grid>
                <Grid container item style={{ width: "12%", }}>
                  <Grid item style={{ margin: '1px', width: '100px', background: accent(6, round) }}> </Grid>
                </Grid>
                <Grid container item style={{ width: "12%", }}>
                  <Grid item style={{ margin: '1px', width: '100px', background: accent(7, round) }}> </Grid>
                </Grid>
                <Grid container item style={{ width: "12%", }}>
                  <Grid item style={{ margin: '1px', width: '100px', background: accent(8, round) }}> </Grid>
                </Grid>
                <Grid container item style={{ width: "12%", }}>
                  <Grid item style={{ margin: '1px', width: '100px', background: accent(9, round) }}> </Grid>
                </Grid>
                <Grid container item style={{ width: "12%", }}>
                  <Grid item style={{ margin: '1px', width: '100px', background: accent(10, round) }}> </Grid>
                </Grid>
                <Grid container item style={{ width: "2%", background: 'transparent' }}></Grid>
              </Grid>

              <Grid container item xs={2} justifyContent="center"></Grid>
              <Grid container item xs={8} style={{ padding: "4px" }}>
                <Grid container item>
                  <Typography
                    align="center"
                    variant="h1"
                    style={{
                      width: "100%",
                      margin: "4px",
                    }}
                  >
                    {round}-zies
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}

      </Grid>


      <Grid container item xs={12} style={{ padding: "4px", background: 'yellow' }}>
        <Typography
          align="center"
          variant="h3"
          style={{
            width: "100%",
            margin: "4px",
          }}
        >
          Well done! You have finished the tutorial
        </Typography>
        <Typography
          align="center"
          variant="h3"
          style={{
            width: "100%",
            margin: "4px",
          }}
        >
          you can finish the tutorial to play the game
        </Typography>


      </Grid>

      <Grid item xs={12} display="flex" justifyContent="center" style={{
        background: "yellow",
      }}>
        <Button
          style={{
            background: "green",
            height: "38.5px",
            color: "white",
            border: "1px solid #0f1919",
            padding: "8px",
            marginTop: "32px",
            marginBottom: "32px",
            marginLeft: "4px", 
          }}
          onClick={(e) => progressTutorial(isLastStep ? undefined : stepNumber + 1)}
        >
          {isLastStep ? 'finish tutorial' : 'next lesson'}
        </Button>
      </Grid>

    </Grid >
  );
};

export default TutorialStep7;
