import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { Grid, ThemeProvider, createTheme } from "@mui/material";
import QuidBone from "./components/QuidBone";
import JoinGame from "./components/JoinGame";
import ErrorPage from "./components/ErrorPage";
import ErrorBoundary from "./components/ErrorBoundary";
import Register from "./components/Register";
import { isMobile } from "react-device-detect";

const theme = createTheme({
  typography: {
    h1: {
      //title
      fontSize: "20px",
      lineHeight: "20px",
      fontWeight: "450",
    },
    h2: {
      //summary headers
      fontSize: "18px",
      lineHeight: "18px",
      fontWeight: "350",
    },
    h3: {
      //prompts
      fontSize: "14px",
      lineHeight: "14px",
      fontWeight: "500",
    },
    h4: {
      //totals
      fontSize: "16px",
      lineHeight: "16px",
      fontWeight: "400",
    },
    h5: {
      //button labels
      fontSize: "16px",
      lineHeight: "16px",
      fontWeight: "250",
    },
    h6: {
      //bone subtext
      fontSize: "13px",
      lineHeight: "13px",
      fontWeight: "350",
    },
    subtitle1: {
      //gamw totals
      fontSize: "14px",
      lineHeight: "14px",
      fontWeight: "300",
    },
  },
});

// border 
// #081400

// lobby turn 
// #aad576 background green
// #245501 text green 
// #ff6d00 highlight
// #7209b7 warning

// lobby not turn 
// #e4f7fb background blue
// #faedcd text green 


// #ffc8dd avatar out
// #a3f7b5 avatar turn
// #transparent avatar empty


// #235789 CTA background
// #f4f3ee CTA color
// e63946 red CTA

//play
// gray d0cdc7
// yellow ffed4d
// green a5c34f



// white 
// #e0e0e0
// #fafafa
// rgba(255,255,255,.87)
// black 
// #0f1919
// #707070
// #253030
//rgba(255,255,255,.2)


function App() {

  const styles = {
    container: {
      background: "#fafafa", height: "100%", width: "100%"
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Grid container justifyContent="center" style={styles.container}>
        <Grid container item style={{
          border: isMobile ? "0px solid #0f1919" : "1px solid #0f1919",
          width: isMobile ? "100%" : "400px"
        }}>
          <BrowserRouter>
            <ErrorBoundary fallback={<ErrorPage />}>
              <Routes>
                <Route path="/:sessionId/join" element={<JoinGame />} />
                <Route path="/register" element={<Register />} />
                <Route path="/" element={<QuidBone />} />
              </Routes>
            </ErrorBoundary>
          </BrowserRouter>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default App;
