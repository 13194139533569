import React, { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";


import _ from "lodash";
import TutorialStep1 from "../TutorialStep1";
import TutorialStep2 from "../TutorialStep2";
import TutorialStep3 from "../TutorialStep3";
import TutorialStep4 from "../TutorialStep4";
import TutorialStep5 from "../TutorialStep5";
import TutorialStep6 from "../TutorialStep6";
import TutorialStep7 from "../TutorialStep7";
import { IProfileSaveRequest } from "../../Types/types";

type TutorialProps = {
  playerName: string;
  completeTutorial: () => void;
  saveProfile: (profile: IProfileSaveRequest) => void;
};

const Tutorial: React.FC<TutorialProps> = ({
  playerName,
  completeTutorial,
  saveProfile
}) => {

  const [step, setStep] = useState<number>(1);

  const progressTutorial = (nextStep: number | undefined) => {
    if (nextStep)
      setStep(nextStep);
    else
      completeTutorial();
  };


  const doSkipTutorial = () => {
    const delta: IProfileSaveRequest = {
      showTutorial: false,
    }
    saveProfile(delta);
  };

  const accent = (x: number, y: number) => {
    if (x < y) return 'orange';
    if (x === y) return 'red';
    return 'lightgrey';
  };

  return (
    <Grid
      container
      style={{
        border: "0px solid #0f1919",
        background: "yellow",
      }}
    >
      <Grid
        container
        style={{
          border: "0px solid #0f1919",
          background: "transparent",
          marginBottom: "24px",
        }}
      >
        <Grid container item xs={12} style={{ maxHeight: "5px", minHeight: "5px" }}>
          <Grid container item style={{ width: "1%", background: 'transparent' }}></Grid>
          <Grid container item style={{ width: "14%", }}>
            <Grid item style={{ margin: '1px', width: '100px', background: accent(1, step) }}>  </Grid>
          </Grid>
          <Grid container item style={{ width: "14%", }}>
            <Grid item style={{ margin: '1px', width: '100px', background: accent(2, step) }}> </Grid>
          </Grid>
          <Grid container item style={{ width: "14%", }}>
            <Grid item style={{ margin: '1px', width: '100px', background: accent(3, step) }}> </Grid>
          </Grid>
          <Grid container item style={{ width: "14%", }}>
            <Grid item style={{ margin: '1px', width: '100px', background: accent(4, step) }}> </Grid>
          </Grid>
          <Grid container item style={{ width: "14%", }}>
            <Grid item style={{ margin: '1px', width: '100px', background: accent(5, step) }}> </Grid>
          </Grid>
          <Grid container item style={{ width: "14%", }}>
            <Grid item style={{ margin: '1px', width: '100px', background: accent(6, step) }}> </Grid>
          </Grid>
          <Grid container item style={{ width: "14%", }}>
            <Grid item style={{ margin: '1px', width: '100px', background: accent(7, step) }}> </Grid>
          </Grid>
          <Grid container item style={{ width: "1%", background: 'transparent' }}></Grid>
        </Grid>
        <Grid container item xs={2} justifyContent="center"></Grid>
        <Grid container item xs={8} style={{ padding: "4px" }}>
          <Grid container item>
            <Typography
              align="center"
              variant="h1"
              style={{
                width: "100%",
              }}
            >
              tutorial
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={2} >
          <Button
            style={{
              background: "green",
              width: "90%",
              height: "38.5px",
              color: "white",
              border: "1px solid #0f1919",
              padding: "8px",
            }}

            onClick={(e) => {
              doSkipTutorial();
            }}
          >
            skip
          </Button>
        </Grid>
      </Grid>


      {/* <Grid container item xs={12} style={{ padding: "4px" }}>
        <Grid container item>
          <Typography
            align="center"
            variant="h2"
            style={{
              width: "100%",
              margin: "4px",
            }}
          >
            Take 2 minutes to have fun
          </Typography>
        </Grid>
      </Grid> */}

      {/* <Grid container item xs={12} style={{ padding: "4px" }}>
        <Grid container item>
          <Typography
            align="center"
            variant="h1"
            style={{
              width: "100%",
              margin: "4px",
            }}
          >
            {titleMessage}
          </Typography>
        </Grid>
      </Grid> */}



      {/* <Grid container item xs={12} style={{ padding: "4px" }}>
        <Grid container item>
          <Typography
            align="center"
            variant="h3"
            style={{
              width: "100%",
              margin: "4px",
            }}
          >
            You can spell a word by pressing the letters. You can go out when you have a 3-letter word
          </Typography>
        </Grid>
      </Grid> */}

      {step === 1 && (
        <Grid item xs={12} style={{ margin: "4px", border: "0px solid #0f1919", borderRadius: "4px" }}>
          <TutorialStep1
            stepNumber={1}
            isLastStep={false}
            progressTutorial={progressTutorial}
          />
        </Grid>
      )}

      {step === 2 && (
        <Grid item xs={12} style={{ margin: "4px", border: "0px solid #0f1919", borderRadius: "4px" }}>
          <TutorialStep2
            stepNumber={2}
            isLastStep={false}
            progressTutorial={progressTutorial}
          />
        </Grid>
      )}

      {step === 3 && (
        <Grid item xs={12} style={{ margin: "4px", border: "0px solid #0f1919", borderRadius: "4px" }}>
          <TutorialStep3
            playerName={playerName}
            stepNumber={3}
            isLastStep={false}
            progressTutorial={progressTutorial}
          />
        </Grid>
      )}

      {step === 4 && (
        <Grid item xs={12} style={{ margin: "4px", border: "0px solid #0f1919", borderRadius: "4px" }}>
          <TutorialStep4
            playerName={playerName}
            stepNumber={4}
            isLastStep={false}
            progressTutorial={progressTutorial}
          />
        </Grid>
      )}

      {step === 5 && (
        <Grid item xs={12} style={{ margin: "4px", border: "0px solid #0f1919", borderRadius: "4px" }}>
          <TutorialStep5
            playerName={playerName}
            stepNumber={5}
            isLastStep={false}
            progressTutorial={progressTutorial}
          />
        </Grid>
      )}

      {step === 6 && (
        <Grid item xs={12} style={{ margin: "4px", border: "0px solid #0f1919", borderRadius: "4px" }}>
          <TutorialStep6
            playerName={playerName}
            stepNumber={6}
            isLastStep={false}
            progressTutorial={progressTutorial}
          />
        </Grid>
      )}

      {step === 7 && (
        <Grid item xs={12} style={{ margin: "4px", border: "0px solid #0f1919", borderRadius: "4px" }}>
          <TutorialStep7
            stepNumber={7}
            isLastStep={true}
            progressTutorial={progressTutorial}
          />
        </Grid>
      )}

    </Grid>
  );
};

export default Tutorial;
