import React, { useState } from "react";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import {
  IChallengeWordSummary,
} from "../../Types/types";
import _ from "lodash";

type ChallengesProps = {
  challenges: IChallengeWordSummary[];
  acknowledgeChallenge: (id: string, isWord: boolean) => void;
  cancelChallenge: (id: string) => void;
  dismissChallenge: (id: string) => void;
};

const Challenges: React.FC<ChallengesProps> = ({
  challenges,
  acknowledgeChallenge,
  cancelChallenge,
  dismissChallenge,
}: ChallengesProps) => {


  const acknowledgeWord = (id: string) => {
    acknowledgeChallenge(id, true);
  };
  const acknowledgeNotWord = (id: string) => {
    acknowledgeChallenge(id, false);
  };

  // const summary = (challenge: IChallengeWordSummary) => {
  //   if (challenge.isResolved && challenge.isWordAllPlayers)
  //     return 'it is a word, ' + (challenge.isChallenger ? 'you' : challenge.challenger) +
  //       ' lose ' + challenge.value + ' points';
  //   else if (challenge.isResolved && challenge.isWordOnePlayer !== undefined && !challenge.isWordOnePlayer)
  //     return 'it is not a word, ' + (challenge.isChallengee ? 'you' : challenge.challengee) +
  //       ' score 0 points';
  //   else if (challenge.isWordAllPlayers !== undefined)
  //     return (challenge.isChallenger ? 'you' : challenge.challenger)
  //       + ' lost ' + challenge.value + ' points';
  //   else if (challenge.isWordOnePlayer !== undefined && challenge.isWordOnePlayer)
  //     return (challenge.isChallengee ? 'you' : challenge.challengee) +
  //       ' still might score 0 points';
  //   else if (challenge.isWordOnePlayer !== undefined && !challenge.isWordOnePlayer)
  //     return (challenge.isChallengee ? 'you' : challenge.challengee) +
  //       ' score 0 points';

  // };

  return (
    <Grid
      display="flex"
      justifyContent="center"
      container
      style={{
        background: "pink",
        paddingBottom: "10px",
      }}
    >

      <Grid container item xs={12} justifyContent="center">
        {challenges.filter(x => (!x.isDismissed)
        ).map((challenge: IChallengeWordSummary, i) => (
          <Grid
            key={"k-cs" + i}
            container
            item
            xs={12}
            style={{
              background: challenge.isResolved ? "lightgreen" : "pink",
              border: "1px solid #0f1919",
              width: "100%",
              marginTop: "10px",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          >
            <Grid
              display={"flex"}
              justifyContent="center"
              container
              item
              xs={12}
              style={{
                background: "transparent",
                border: "0px solid #0f1919",
                padding: "4px",
                paddingRight: "0px",
                marginTop: "8px",
              }}
            >
              <Typography
                align="center"
                variant="h1"
                style={{
                  color: "black",
                  marginRight: "4px",
                }}
              >
                {challenge.isChallenger ? "you" : challenge.challenger}
              </Typography>
              <Typography
                align="center"
                variant="h1"
                style={{
                  color: "black",
                  marginRight: "4px",
                }}
              >
                {challenge.isChallenger ? " have challenged" : "has challenged"}
              </Typography>
              <Typography
                align="center"
                variant="h1"
                style={{
                  color: "black",
                  marginRight: "0px",
                }}
              >
                {challenge.isChallengee ? "you" : challenge.challengee}
              </Typography>
            </Grid>

            {challenge.isWordOnePlayer === undefined &&
              !challenge.isWordOnePlayer && (
                <Grid
                  display={"flex"}
                  justifyContent="center"
                  container
                  item
                  xs={12}
                  style={{
                    background: "transparent",
                    border: "0px solid #0f1919",
                    padding: "8px",
                    marginLeft: "0px",
                    marginRight: "0px",
                  }}
                >
                  <Typography
                    align="center"
                    variant="h4"
                    style={{
                      color: "black",
                      marginRight: "8px",
                    }}
                  >
                    Is
                  </Typography>
                  <Typography
                    align="center"
                    variant="h2"
                    style={{
                      color: "black",
                      marginRight: "8px",
                    }}
                  >
                    {challenge.cards.map((x) => x.letters).join(" ")}
                  </Typography>

                  <Typography
                    align="center"
                    variant="h4"
                    style={{
                      color: "black",
                      marginRight: "0px",
                    }}
                  >
                    a word?
                  </Typography>
                </Grid>
              )}

            {challenge.isWordOnePlayer !== undefined && (
              <Grid
                display={"flex"}
                justifyContent="center"
                container
                item
                xs={12}
                style={{
                  background: "transparent",
                  border: "0px solid #0f1919",
                  padding: "8px",
                  marginLeft: "0px",
                  marginRight: "0px",
                }}
              >
                <Typography
                  align="center"
                  variant="h4"
                  style={{
                    color: "black",
                    marginRight: "8px",
                  }}
                >
                  you think that
                </Typography>
                <Typography
                  align="center"
                  variant="h2"
                  style={{
                    color: "black",
                    marginRight: "8px",
                  }}
                >
                  {challenge.cards.map((x) => x.letters).join(" ")}
                </Typography>

                <Typography
                  align="center"
                  variant="h4"
                  style={{
                    color: "black",
                    marginRight: "0px",
                  }}
                >
                  {!challenge.isWordOnePlayer ? "is not" : "is"} a word?
                </Typography>
              </Grid>
            )}

            {/* <Grid
              display={"flex"}
              justifyContent="center"
              container
              item
              xs={12}
              style={{
                background: "transparent",
                border: "0px solid #0f1919",
                padding: "8px",
                marginLeft: "0px",
                marginRight: "0px",
              }}
            >
              <Typography
                align="center"
                variant="h4"
                style={{
                  color: "black",
                  marginRight: "0px",
                }}
              >
                {summary(challenge)}
              </Typography>
            </Grid> */}

            {challenge.isWordOnePlayer === undefined && (
              <Grid
                container
                item
                xs={12}
                direction={"row"}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "4px",
                }}
              >
                <Grid
                  container
                  item
                  xs={12}
                  style={{
                    justifyContent: "center",
                    //   margin: "4px",
                  }}
                >

                  <Grid
                    container
                    item
                    xs={12}
                    style={{
                      justifyContent: "center",
                      // marginTop: "4px",
                      // marginBottom: "16px",
                    }}
                  >
                    <Button
                      style={{
                        background: "green",
                        width: "100%",
                        //  height: "40px",
                        textTransform: "none",
                        alignItems: "center",
                        border: "1px solid #0f1919",
                      }}
                      onClick={(e) => acknowledgeWord(challenge.challengeId)}
                    >
                      <Grid
                        container
                        item
                        xs={12}
                        style={{
                          justifyContent: "center",
                          // marginTop: "4px",
                          // marginBottom: "4px",
                        }}
                      >
                        <Typography
                          align="center"
                          variant="h5"
                          style={{
                            color: "white",
                            // paddingTop: "8px",
                            // paddingRight: "4px",
                          }}
                        >
                          yes! - {challenge.isChallenger ? 'you' : challenge.challenger} might lose {challenge.value} points
                        </Typography>
                      </Grid>
                    </Button>
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  style={{
                    justifyContent: "center",
                    marginTop: "4px",
                    // marginBottom: "16px",
                  }}
                >

                  <Button
                    style={{
                      background: "green",
                      width: "100%",
                      //height: "40px",
                      border: "1px solid #0f1919",
                      textTransform: "none",
                      margin: "0px",
                    }}
                    onClick={(e) => acknowledgeNotWord(challenge.challengeId)}
                  >
                    <Grid
                      container
                      item
                      xs={12}
                      style={{
                        justifyContent: "center",
                        // marginTop: "4px",
                        // marginBottom: "4px",
                      }}
                    >
                      <Typography
                        align="center"
                        variant="h5"
                        style={{
                          color: "white",
                          // paddingTop: "8px",
                          // paddingLeft: "4px",
                        }}
                      >
                        no! - {challenge.isChallengee ? 'you' : challenge.challengee} will miss out on {challenge.value} points
                      </Typography>
                    </Grid>
                  </Button>

                </Grid>

              </Grid>
            )}

            {challenge.canCancel && (
              <Grid
                container
                item
                xs={12}
                direction={"row"}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "4px",
                }}
              >
                <Grid
                  container
                  item
                  style={{
                    justifyContent: "center",
                  }}
                >
                  <Button
                    style={{
                      background: "red",
                      color: "white",
                      width: "100%",
                      //  height: "38.5px",
                      textTransform: "none",
                      borderTop: "1px solid #0f1919",
                    }}
                    onClick={(e) => {
                      cancelChallenge(challenge.challengeId);
                    }}
                  >
                    cancel challenge
                  </Button>
                </Grid>
              </Grid>
            )}

            {challenge.isWordOnePlayer !== undefined && (
              <Grid
                container
                item
                xs={12}
                direction={"row"}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Grid
                  container
                  item
                  style={{
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    align="center"
                    variant="h5"
                    style={{
                      color: "black",
                      marginRight: "4px",
                      paddingTop: "12px",
                    }}
                  >
                  </Typography>
                  <Button
                    style={{
                      background: "red",
                      color: "white",
                      width: "100%",
                      height: "38.5px",
                      borderTop: "1px solid #0f1919",
                    }}
                    onClick={(e) => {
                      dismissChallenge(challenge.challengeId);
                    }}
                  >
                    dismiss
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default Challenges;
