import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import _ from "lodash";

type DeboneOverlayProps = {
  bone: string;
  player: string;
};

const DeboneOverlay: React.FC<DeboneOverlayProps> = ({
  bone, player,
}: DeboneOverlayProps) => {

  return (

    <div style={{
      position: 'relative',
      width: "100%"
    }}>
      <Grid container item   >
        <Grid container item  >
          <Grid
            justifyContent={"center"}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              height: '100%',
              width: '89.75px',
              //opacity: 0.4,
              backgroundColor: 'transparent',
            }}
          >
            <Typography
              align="center"
              variant="h2"
              style={{
                color: bone === 'long bone' ? "purple" : "darkorange",
                marginRight: "4px",
              }}>
              {bone}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container item
          justifyContent={"center"}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            // marginTop: "-4px",
            marginLeft: "--89.",
            // // marginRight: "-20px",
            transform: "rotate(-30deg)",
          }}
        >
          <Typography
            align="center"
            style={{
              fontSize: "12px",
              color: "red",
              lineHeight: "12px",
              fontWeight: 400,
              width: "100%",
              marginTop: "0px",
              marginLeft: "0px",
              marginRight: "0px",
            }}
          >
            DEBONED
          </Typography>
          <Typography
            align="center"
            style={{
              fontSize: "12px",
              color: "red",
              lineHeight: "12px",
              fontWeight: 300,
              width: "100%",
              marginTop: "0px",
              marginLeft: "0px",
              marginRight: "0px",
            }}
          >
            {" "}
            by {player}
          </Typography>
        </Grid>
      </Grid>
    </div>


  );
};

export default DeboneOverlay;
